.b-faq {
  position: relative;
  &:before {
    content: 'FAQ';
    position: absolute;
    bottom: -50px;
    right: 30px;
    font-size: 450px;
    font-weight: 700;
    line-height: 1;
    color: #FFDB0A;
    opacity: 0.1;
    z-index: -1;
  }

  @include media-breakpoint-down(lg) {
    &:before {
      font-size: 300px;
      bottom: 0;
      right: 0;
    }
  }

  @include media-breakpoint-down(md) {
    margin-top: 54px;
    &:before {
      font-size: 184px;
      top: -90px;
      bottom: auto;
      right: -80px;
    }
  }
}

.b-faq-group {
  overflow: hidden;
}