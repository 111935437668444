.app-container {
  width: 100%;
  overflow-x: hidden;
}

.app-control {
  height: 800px;
  background-color: #000;
  background: #000 url('../../assets/img/content/b-app/bg1.webp');
  background-position: calc(50% - 270px) calc(50% - 30px);
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;

  &-texts {
    width: 550px;
    margin-left: calc(50% - 60px);
    padding-top: 80px;

    &__desc {
      max-width: 430px;
      margin-bottom: 70px;
    }

    &__icons {
      > a {
      display: inline-block;

      &:first-child {
        margin-right: 30px;
      }
      }

      &.mobile {
        img {
          position: relative;
          top: -90px;
        }
      }
    }
  }

  @include media-breakpoint-down(xl) {
    background-position: calc(50% - 240px) calc(50% - -10px);
  }
}

.app-title {
  font-weight: 700;
  font-size: 40px;
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 20px;
  line-height: 1.2;
}

.app-title2 {
  font-size: 30px;
  color: #fff;
  margin-bottom: 40px;
}


.app-desc {
  color: #fff;
  font-size: 25px;
}


.app-notice {
  font-size: 22px;
  color: #fff;
  margin-top: 20px;
}


.app-change-avto {
  background-color: #fff;
  padding-top: 155px;
  position: relative;
  z-index: 2;

  .app-title,
  .app-title2,
  .app-desc,
  .app-notice {
    color: #000;
    }

    &.app-change-avto_2 {
    .app-change-avto-desc {
      margin-right: 0;
      width: 400px;
    }
  }

  &-wrapper {
    display: flex;
    justify-content: center;

    // &.app-change-avto-wrapper_2 {
    //   flex-direction: column-reverse;
    //   display: flex;

    //   .app-change-avto__text,
    //   .app-change-avto__image {
    //     margin-left: 0;
    //   }
    // }
  }


  &__text {
    width: 380px;
    margin-right: 30px;

    &.status-list {
      margin-right: 0;
      margin-left: 75px;
    }

    br:not(.br) {
      display: none;
    }
  }

  &__desc {
    width: 360px;

    &.status-list {
      width: 100%;
    }
  }

  &__image {
    &.status-list {
      margin-left: 120px;
    }
  }

  .container {
    // padding: 0 30px;

    &.status-list {
      padding: 0 15px;
    }
  }
}


.app-change-avto-wrapper_2 {
  .app-change-avto__text {
    margin-right: 0;
    margin-left: 75px;
  }
  .app-change-avto__image {
    margin-left: 120px;
  }
}


.app-install {
  background-color: #fff;
  padding-top: 140px;

  .app-title {
    text-align: center;
    color: #000;

    &.status-list {
      text-align: left;
      margin-left: 0;
      text-align: center;
      padding: 0 30px;
    }
  }

  &-images {
    text-align: center;
    position: relative;
    overflow: hidden;
    margin-top: 40px;

    &:before {
      content: '';
      position: absolute;
      top: 50px;
      right: 0;
      bottom: 0;
      left: 0;
      height: calc(100% + 300px);
      display: block;
      background: radial-gradient(ellipse at center, rgba(74, 74, 74, 0.65) 0%, rgba(0, 0, 0, 0) 50%);
      z-index: -1;
    }

    .img {
      display: inline-block;
    }
  }

  &-link {
    display: inline-block;
    padding-top: 60px;
    padding-bottom: 60px;
    .ic {
      width: auto;
      height: 71px;
      transition: all .3s;

      &:hover {
        opacity: 0.6;
      }
    }
  }

  &-text-link {
    color: #000;
    text-transform: uppercase;
    font-size: 20px;
    display: inline-block;
    margin-top: 20px;
    border-bottom: 1px solid #000;
    line-height: 1.2;

    &:hover {
      border: none;
    }
  }

  &.app-install-mobile {
    display: none;

    &-item__image,
    &-item__links {
      text-align: center;
    }

    &-item__links > a {
      display: block;
      padding: 25px;
      margin: auto;
      max-width: 370px;
    }

    &-item {
      position: relative;
      overflow: hidden;

      &:before {
      content: '';
      position: absolute;
      top: 36%;
      right: 0;
      bottom: 0;
      left: 0;
      height: 63%;
      display: block;
      background: radial-gradient(ellipse at center, rgba(107, 107, 107, 0.76) 0%, rgba(0, 0, 0, 0) 82%);
      z-index: -1;
      }

      &:not(:last-child) {
      margin-bottom: 30px;
      }
    }

    &.app-install-mobile__item:not(:last-child) {
      margin-bottom: 30px;
    }
  }
}


.oferta-header {
  font-size: 30px;
  text-transform: uppercase;
}

h2.title.w0 {
  width: auto;
}

@include media-breakpoint-down(xl) {
  .app-control-texts {
    margin-left: 50%;
  }

  .app-change-avto-wrapper_2 .app-change-avto__image {
    margin-left: 10%;
  }

  .app-change-avto-wrapper_2 .app-change-avto__text {
    margin-left: 7.5%;
  }
}


@include media-breakpoint-down(lg) {
  .app-container {
    margin-top: 0;
    width: 100%;
    overflow-x: hidden;

    img {
      max-width: 100%;
      height: auto;
    }
  }

  .app-control-texts {
    margin-left: 0;
    padding: 0 20px;
    width: auto;
  }

  .app-notice {
    font-size: 16px;
  }

  .app-title {
    font-size: 38px;
    text-align: center;
  }

  .app-title2 {
    text-align: center;
  }

  .app-desc {
    font-size: 20px;
  }

  .app-control {
    background: #000;
    padding-top: 60px;
    height: auto;
  }

  .app-control-texts__desc {
    max-width: 100%;
    margin-bottom: 100px;
  }

  .app-control-texts__icons {
    &.mobile {
      padding-top: 0px;
      text-align: center;
      height: 560px;
      background: url('../../assets/img/content/b-app/bg1.webp');
      background-position: 50% 21%;
      background-repeat: no-repeat;
      background-size: 130%;
      padding-top: 12px;
    }
  }

  .app-change-avto {
    padding-top: 70px;
    margin-top: -2px;

    .container {
      padding: 0 30px;
    }

    .app-change-avto-wrapper {
      display: block;
    }
  
    .app-change-avto-wrapper_2 {
      display: flex;
      flex-direction: column-reverse;
    }

    .app-change-avto__text {
      width: auto;
      margin-right: 0;
    }

    .app-change-avto__image {
      margin-top: 35px;
      margin-left: 0;
      text-align: center;
    }

    .app-change-avto-desc,
    &.app-change-avto_2 .app-change-avto-desc {
      width: auto;
    }


    &.app-change-avto-wrapper_2 {
      flex-direction: column-reverse;
      display: flex;
    }

    &.status-list .container {
      padding: 0 15px;
    }
  }

  .app-change-avto-wrapper_2 .app-change-avto__text {
    margin-left: 0;
  }

  .app-install {
    margin-top: -2px;
    padding-top: 70px;

    .app-title {
      text-align: left;
      margin-left: 0;
      text-align: center;
      padding: 0 30px;
    }
  }
}



@include media-breakpoint-down(sm) {
  .app-control-texts,
  .app-install .app-title {
    margin-left: 0;
    padding: 0 15px;
    text-align: center;
  }

  .app-control-texts__icons.mobile {
    background: url('../../assets/img/content/b-app/bg1-mobile.webp') 0 0 no-repeat;
    background-size: contain;
    margin-top: -60px;
    height: 390px;
    img {
      top: -20px;
    }
  }

  .app-change-avto .container {
    padding: 0 15px;
  }

  .app-change-avto__image img,
  .app-install-mobile-item__image img {
    width: 60%;
  }

  .app-install-mobile-item:before {
    top: 25%;
    background: radial-gradient(ellipse at center, rgba(153, 153, 153, 0.99) 0%, rgba(0, 0, 0, 0) 82%);
  }

  .app-title {
    font-size: 26px;
  }

  .app-title2 {
    font-size: 24px;
  }
}

.ic-appStore {
  width: 165px;
  height: 50px;
}
.ic-ggPlay {
  width: 144px;
  height: 50px;
}

.ic-appStore,
.ic-ggPlay {
  transition: all .3s;

  &:hover {
    opacity: 0.6;
  }
}