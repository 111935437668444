.gan-video {
  display: table;
  margin: 0 auto;
} 

.box {
  position: relative;
  width: 370px;
  height: 500px;
  margin: 0 auto;
  transition: all 1s;

  &.box-open {
    transition: all 1s;

    .box-top {
      left: 190px;
      bottom: 250px;
      transition: all 1s;
    }
  }

  .box-bottom {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
  }

  .box-top {
    position: absolute;
    left: 28px;
    z-index: 2;
    bottom: 113px;
    transition: all 1s;
  }

  @include media-breakpoint-down(lg) {
    width: 300px;
    height: 340px;
    transform: translateX(-100px);

    &.box-open {
      .box-top {
        left: 140px;
        bottom: 140px;
      }
    }

    .box-bottom {
      width: 300px;
    }
  }

  @include media-breakpoint-down(md) {
    transform: translateX(-50px);
  }

  @include media-breakpoint-down(sm) {
    width: 250px;
    height: 250px;
    transform: translateX(0);

    &.box-open {
      .box-top {
        left: 65px;
        bottom: 100px;
      }
    }

    .box-bottom {
      width: 220px;
    }
  }
}

.box2 {
  .box-top {
    left: 39px;
    bottom: 143px;
  }

  @include media-breakpoint-down(lg) {
    .box-top {
      width: 225px;
      left: 39px;
      bottom: 134px;
    }
    
    &.box-open {
      .box-top {
        bottom: 190px;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .box-top {
      width: 165px;
      left: 29px;
      bottom: 98px;
    }

    &.box-open {
      .box-top {
        bottom: 150px;
      }
    }
  }
}