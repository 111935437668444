.review-block {
  .title {
    margin-bottom: 25px;
  }
  &:not(.review-block_mod-a) {
    .title {
      padding-top: 0;
    }
  }
  .subtitle {
    margin-bottom: 50px;
    font-size: 25px;
  }

  @include media-breakpoint-down(sm) {
    .title {
      margin-bottom: 20px;
      font-size: 40px;
    }
    .subtitle {
      margin-bottom: 25px;
      font-size: 20px;
    }
  }
}

.review-slider {
  margin-right: -30px;
  &__item {
    margin-right: 30px;
  }
  .slick-next {
    right: -10px;
  }

  @include media-breakpoint-down(xl) {
    .slick-next {
      right: 0;
    }
  }

  @include media-breakpoint-down(md) {
    margin-right: 0;
    &__item {
      margin-right: 0;
    }
  }
}

.reviews-top {
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  margin: 0 auto 35px;
  padding: 6px 20px 6px 6px;
  background-color: #f3f3f3;

  &__link {
    margin-right: 15px;
    padding: 12px 14px;
    background-color: #fff;
    text-decoration: none;
    font-size: 15px;
    line-height: 15px;
    color: #000000;

    &:hover {
      background-color: #a2cd1d;
      color: #000;
    }
  }
  &__yan-maps {
    margin-right: 29px;  
  }
  &__yan-maps,
  &__goog-maps {
    display: flex;
    align-items: center;
    font-size: 15px;
  }
  &__yan-maps strong,
  &__goog-maps strong {
    margin-left: 11px;
  }
  &__yan-maps .ic,
  &__goog-maps .ic {
    margin-right: 11px;
  }


  @include media-breakpoint-down(sm) {
    margin-bottom: 20px;
    padding: 0;
    background-color: #fff;
    &__link {
      display: none;
    }
    &__yan-maps strong,
    &__goog-maps strong {
      margin-left: 5px;
    }
    &__yan-maps .ic,
    &__goog-maps .ic {
      margin-right: 5px;
    }
  }
}




.review {
  &__item {
    width: 300px;
    height: 382px;
    background-color: #f3f3f3;
    padding: 20px 25px;
    display: flex;
    flex-direction: column;
  }
  &__item--header {
    display: flex;
    margin-bottom: 20px;
  }
  &__item-photo {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    margin-right: 15px;
    font-size: 30px;
    letter-spacing: 2px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    background-color: #8f9391;
  }
  &__item-info {
    max-width: 67%;
    width: 100%;
  }
  &__item-info--rating {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 5px;
  }
  &__item-info--name {
    font-weight: 700;
    font-size: 17px;
    line-height: 19px;
  }
  &__item-info--date {
    font-size: 14px;
  }
  &__item--body {
    font-size: 14px;
    line-height: 18px;
  }
  &__item--footer {
    margin-top: auto;
    a {
      font-size: 14px;
    }
  }
}











.review-container {
  &:not(:last-child) {
    margin-bottom: 30px;
  }

  &__header {
    font-size: 20px;
    margin-bottom: 8px;
  }


  &__data {
    margin-bottom: 14px;

    .review-container__text {
      padding-left: 25px;
      font-size: 14px;
      margin-bottom: 0;

      br {
        display: none;
      }
    }
  }

  &__subheader_stars {
    position: relative;
    flex: none;

    .ic {
      width: 54px;
      height: 27px;
      height: auto;
      stroke: #A2CD1E;
      stroke-width: 2px;
      fill: none;
    }
    span {
      position: absolute;
      top: 0;
      bottom: 2px;
      left: 0;
      right: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__text_header {
    font-size: 16px;
    margin-bottom: 5px;
  }

  &__text {
    font-size: 16px;
    margin-bottom: 22px;
  }

  &__likes {
    display: flex;
    align-items: center;
    padding: 0 0 20px;

    .like,
    .dislike {
      position: relative;
      display: inline-block;
      margin-left: 12px;
      fill: #adadad;
      .ic {
        width: 26px;
        height: auto;
      }

      &.like {
        top: -3px;
        &:hover,
        &.active {
          fill: #8fbb07;
        }
      }

      &.dislike {
        top: 2px;
        transform: scale(-1, -1);
        &:hover,
        &.active {
          fill: #ff0101;
        }
      }

    }

    .like-cnt {
      padding-left: 8px;
      font-size: 16px;
      color: #8fbb07;
    }

    .dislike-cnt {
      padding-left: 8px;
      font-size: 16px;
      color: #ff0101;
    }

    a.like,
    a.dislike {
      cursor: pointer;
    }
  }

  &__comment {
    display: flex;
    justify-content: flex-end;

    &__body {
      width: calc(100% - 55px);
      background-color: #ebebeb;
      padding: 15px 20px;
    }
  }

  &__video {
    padding: 15px 0 35px;

    iframe {
      width: 100% !important;
    }
  }


  @include media-breakpoint-down(sm) {
    &__likes {
      .like,
      .dislike {
        .ic {
          width: 40px;
        }
      }
    }
  }
}


.reviews-wrapper {
  display: flex;
}



.add-review-button {
  margin-bottom: 35px;

  .btn {
    width: 100%;
  }
}


.reviews-top-filter {
  padding-bottom: 35px;
  &__models {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    a {
      width: 25%;
      padding: 0 9px;
      margin-bottom: 18px;
      text-decoration: none;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    height: 50px;
    background-color: #cccccc;
    color: #000;
    font-size: 20px;

    &.selected {
      background-color: #a2cd1d !important;
    }

    &.level2 {
      background-color: #cccccc;

      &:hover {
        background-color: #a2cd1d;
        cursor: pointer;
      }
    }

    &.level3 {
      background-color: #a9a9a9;

      &:hover {
        background-color: #a2cd1d;
        cursor: pointer;
      }
    }

    &.level4 {
      background-color: #fff;
      border: 1px solid #c2c2c2;

      &:hover {
        background-color: #a2cd1d;
        cursor: pointer;
      }
    }
  }
}


.reviews-count {
  flex: none;
  display: inline-block;
}


.filter-block {
  width: 270px;
  padding-right: 9px;
  &__section {
    &:not(:last-child) {
      margin-bottom: 35px;
    }
  }

  &__item {
    margin-bottom: 4px;
  }

  &__title {
    font-size: 15px;
    margin-bottom: 6px;
    display: block;
  }

  &.filter-review {
    font-size: 15px;
    line-height: 1.3;

    .filter-block__item {
      margin-bottom: 4px;

      a {
        min-height: 20px;
        line-height: 15px;
        transition: all .3s;
      }
    }

    .filter-block__title {
      font-size: 15px;
      margin-bottom: 6px;
    }

    .filter-block__item.active a {
      font-weight: 700;
      text-decoration: none;
    }

    .filter-block__item:not(.active) a {
      color: #026fdf !important;
      text-decoration: none;

      &:hover {
        color: #23527c;
        text-shadow: 1px 1px 0 #23527c;
        text-decoration: none;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    width: 100%;
    padding: 0 9px;
    margin-bottom: 35px;
  }
}


.star-block {
  display: inline-block;
  vertical-align: middle;
  margin-right: 2px;
}

.rait-percent {
  margin-left: 2px;
  color: #b3b3b3;
}
.likes-title {
  font-size: 14px;
  color: #adadad;
}
.rait-data {
  display: inline-flex;
  align-items: flex-end;
}


.reviews-block {
  width: calc(100% - 270px);
  padding-right: 100px;
  padding-left: 9px;

  @include media-breakpoint-down(xl) {
    padding-right: 30px;
  }

  @include media-breakpoint-down(lg) {
    width: 100%;
    padding: 0 9px;
  }
}

@include media-breakpoint-down(lg) {
  .reviews-wrapper {
    flex-direction: column-reverse;
  }
  .add-review-button .btn{
    width: auto;
    display: table;
    margin: 0 auto;
  }
}


@include media-breakpoint-down(md) {
  .review__comment__body {
    width: 100%;
    position: relative;

    &::before,
    &::after {
      content: '';
      width: 30px;
      height: 100%;
      background-color: inherit;
      position: absolute;
      top: 0;
    }

    &::before {
      left: 0;
      transform: translateX(-100%);
    }

    &::after {
      right: 0;
      transform: translateX(100%);
    }
  }

  .review-container__header__data {
    .review-container__text {
      padding-left: 20px;

      strong {
        font-weight: 400;
      }
    }
  }

}



@include media-breakpoint-down(sm) {
  .reviews-top-filter {
    padding-bottom: 40px;
  }

  .filter-review {
    .filter-block__section,
    .filter-block__title {
      font-size: 15px;
    }

    .filter-block__section:not(:last-child) {
      margin-bottom: 45px;
    }
  }

  .reviews-count {
    font-size: 15px; 
  }

  .review-container {
    &__header {
      margin-bottom: 13px;

      &__data {
        margin-bottom: 27px;

        .review-container__text {
          font-size: 13px;
          padding-left: 15px;

          br {
            display: block;
          }
        }
      }
    }

    &__text {
      margin-bottom: 27px;
    }

    &__likes {
      padding-bottom: 50px;

      .like,
      .dislike {
        margin-left: 20px;
      }

      .like-cnt,
      .dislike-cnt {
        font-size: 25px;
      }

      .likes-title {
        font-size: 23px;
        padding-right: 5px;
      }
    }
  }

  .rait-data {
    display: flex;
    align-items: center;
  }
}



.addreview-page {
  padding-top: 60px;
  padding-bottom: 50px;

  .error {
    color: red;

    &.inactive {
      display: none !important;
    }
  }
}

.review-row {
  margin-bottom: 25px;

  &__left {
    flex: 0 0 150px;

    &__title {
      margin: 0;
      padding-top: 20px;
      padding-right: 20px;
    }
  }

  &__right {
    flex-grow: 1;
    position: relative;
  }

  &__title {
    margin-bottom: 22px;
    font-size: 25px;
  }


  input.btn {
    margin-top: 15px;
  }

  .form__input {
    width: 65%;
    text-transform: initial;
    border-color: #959595;

    &::placeholder {
      color: #959595;
      text-transform: initial;
      font-size: 18px;
    }
  }

  .bottom-text {
    font-size: 13px;
    line-height: 1.3;
    padding-top: 10px;
  }

  .no-margin {
    margin-bottom: 0;
  }

  &__notice {
    position: absolute;
    width: 35%;
    right: 0;
    top: 20px;
    padding-left: 30px;
    font-size: 16px;
    line-height: 1.375;
    color: #959595;
  }

  @include media-breakpoint-down(lg) {
    &__notice {
      position: static;
      width: 100%;
      padding-left: 0;
      padding-top: 10px;
    }

    .form__input {
      width: 100%;
    }
  }
}

#reviewStars-input {
  display: inline-flex;
  flex-direction: row-reverse;
  position: relative;

  input {
    display: none;

    &:checked ~ label {
      background: url('../../assets/img/content/ic/star-fill.svg') no-repeat;
      background-size: contain;
    }
  }

  label {
    width: 40px;
    height: 38px;
    float: right;
    cursor: pointer;
    margin-right: 5px;
    position: relative;
    z-index: 1;
    background: url('../../assets/img/content/ic/star-stroke.svg') no-repeat;
    background-size: contain;

    &:hover,
    &:hover ~ label {
      background: url('../../assets/img/content/ic/star-fill.svg') no-repeat;
      background-size: contain;
    }
  }
}

.total-rait {
  margin-bottom: 45px;

  &__title {
    font-size: 25px;
    margin-bottom: 15px;
  }

  &__mark {
    font-size: 18px;
    margin-left: 15px;
  }

  &__stars {
    position: relative;

    .error {
      position: absolute;
      top: calc(100% + 5px);
    }
  }
}

.choose-type {
  padding-bottom: 15px;

  &__item {
    margin-bottom: 20px;

    input {
      display: none;

      &:checked + label {
        color: #000;

        &::before {
          background-color: #a2cd1d;
        }
      }
    }

    label {
      font-size: 14px;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      margin: 0;
      color: #959595;
      cursor: pointer;
      transition: color 0.2s;

      &:hover {
        color: #000;
      }

      &::before {
        content: '';
        flex: none;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 5px solid #959595;
        background-color: #959595;
        margin-right: 10px;
        transform: translateY(-2px);
        transition: background-color 0.2s;
      }
    }

    &.error label::before {
      border-color: #ff0000 !important;
      background-color: #ff0000 !important;
    }
  }
}

.addreview-page div.error {
  padding-left: 30px;
  padding-top: 5px;
}

.user-data {
  .input-wrapper {
    flex: 0 1 335px;
    margin-right: 25px;
    margin-bottom: 15px;

    &:last-child {
      margin-right: 0;
    }
  }

  @include media-breakpoint-down(xl) {
    .input-wrapper {
      flex: 0 1 325px;
    }
  }
}

textarea.form__input {
  resize: none;
  padding-top: 15px;
  height: 155px;
}

.review-thanks {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  font-size: 25px;
  text-align: center;
  padding-top: 120px;
  z-index: 10;
  display: none;
}


@include media-breakpoint-down(md) {
  .review-row {
    flex-wrap: wrap;
    margin-bottom: 35px;

    &__left {
      flex: 1 0 100%;
      margin-bottom: 10px;

      &__title {
        padding: 0;
      }
    }
  }

  .total-rait {
    margin-bottom: 30px;

    &__content {
      flex-direction: column;
      align-items: flex-start;
    }

    &__stars .error {
      position: relative;
      top: 0;
    }

    &__mark {
      margin-left: 0;
    }
  }

  .addreview-page {
    div.error {
      padding-left: 0;
    }
  }

  .review-row {
    input.btn {
      display: table;
      margin-left: auto;
      margin-right: auto;
    }

    &__right {
      .bottom-text {
        text-align: center;
        max-width: 270px;
        margin: 0 auto;

        br {
          display: none;
        }
      }
    }

    &__title {
      p {
        margin-top: 5px;
      }
    }
  }

  .user-data .input-wrapper {
    flex: 0 1 100%;
    margin-right: 0;
  }
}


@include media-breakpoint-down(sm) {
  .addreview-page {
    padding-top: 30px;
  }

  .review-row__title {
    line-height: 1.2;
  }

  .choose-type__item {
    label {
      line-height: 1.3;
      align-items: flex-start;
    }
  }
}