.power {
  &__line {
    width: 100%;
    display: flex;
    height: 60px;
    margin-bottom: 40px;
    line-height: 1;

    &--first {
      .power__line__green {
        .power__data {
          position: relative;
          left: 55px;
        }
      }
    }

    &--second {
      .power__line__grey {
        width: 55%;
      }
    }

    &__grey {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px 0 25px;
      background-color: #d7d7d7;
    }


    &__green {
      flex: none;
      width: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background: linear-gradient(to right, #a0cc1c, #248701);
      transition: width 2s;

      &.box-open {
        width: 370px;

        .power__data {
          opacity: 1;
        }
      }

      .power__data {
        opacity: 0;
        transition: opacity .4s;
      }
    }
  }

  &__title {
    font-size: 24px;
  }

  &__title__mobile {
    display: none;
  }

  &__data {
    font-size: 33px;
  }

  &__name {
    font-size: 20px;
  }

  &__img {
    margin: auto 20px;
  }

  &__num {
    display: flex;
    align-items: baseline;
    justify-content: center;
    width: 105px;
    height: 33px;
    background-color: #ffdb0a;
    font-size: 25px;
    line-height: 33px;
    font-weight: bold;

    span {
      margin-left: 3px;
      font-size: 20px;
      font-weight: 500;
    }
  }


  @include media-breakpoint-down(lg) {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    &__name {
      font-size: 16px;
    }
    &__img {
      margin: auto 5px;
    }
    &__num {
      width: 85px;
      height: 27px;
      font-size: 21px;
      line-height: 27px;
      span {
        font-size: 16px;
      }
    }

    &__line {
      width: 60px;
      height: 420px;
      flex-direction: column-reverse;
      margin: 0 30px 40px; 
    }
    &__line--first .power__line__grey {
      height: 220px; 
    }
    &__line--first .power__line__green.box-open {
      height: 160px;
      transition: height 1s; 
    }
    &__line--second .power__line__grey {
      height: 280px; 
    }
    &__line--second .power__line__green.box-open {
      height: 170px;
      transition: height 1s; 
    }
    &__line--second .power__title.power__title__mobile {
      right: auto;
      left: 0; 
    }

    &__line__green {
      height: 0;
      width: 60px; 
    }

    &__line__grey,
    &__line__green,
    &__line__green.box-open,
    &__line--second .power__line__grey {
      width: 60px; 
    }

    &__line__green {
      background: linear-gradient(to top, #a0cc1c, #248701);
      padding: 7px; 
    }

    &__line--first .power__line__green .power__data {
      left: 0; 
    }

    &__line__grey {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      position: relative; 
    }
    &__line__grey .power__title {
      display: none; 
    }
    &__line__grey .power__data {
      line-height: 1.4; 
    }

    &__title.power__title__mobile {
      display: block;
      position: absolute;
      bottom: -50px;
      font-size: 20px;
      right: 0; 
    }
    &__line.power__line--first .power__title.power__title__mobile{
      bottom: -30px;
    }

    &__data strong span {
      display: block; 
    }
    &__data {
      font-size: 26px; 
    }
  }

  @include media-breakpoint-down(md) {
    &__img {
      display: flex;
      justify-content: flex-end;
      flex-grow: 1;
      margin-right: 8px;
    }
  }
}


.power-top {
  &--block {
    margin-top: 60px;
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
  }

  &__power {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 10px;
  }

  &__title {
    text-transform: uppercase;
    font-size: 25px;
    font-weight: 700;
  }

  &__torque {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }



  @include media-breakpoint-down(md) {
    &:not(:last-of-type) {
      margin-bottom: 30px;
    }

    &--block {
      display: block;
      // margin-bottom: 0;
    }
    &__power, 
    &__torque {
      justify-content: space-between;
      align-items: center;
      line-height: 1;
    }
  }
}
