.b-press {
  margin-top: 85px;
  padding-bottom: 68px;
  background-color: #000;
  text-align: center;
  color: #FFF;

  &__row {
    display: flex;
    padding-top: 14px;
    margin: 0 -20px;
  }

  &__item {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    padding: 0 40px;
  }

  &__title {
    margin-bottom: 16px;
    font-size: 22px;
    font-weight: 700;
  }

  &__logo {
    display: inline-block;
    height: 307px;
  }

  &__info {
    font-size: 18px;
    margin-bottom: 23px;
    line-height: 1.39;
  }

  &__link {
    font-size: 20px;
    color: #CF985E;
    text-decoration: underline;
    display: inline-block;

    &:hover,
    &:focus {
      color: #fff;
    }
  }


  @include media-breakpoint-down(lg) {
    padding-bottom: 38px;

    .title {
      padding-top: 90px;
    }
    &__row {
      margin: 0 -40px;
    }
  }

  @include media-breakpoint-down(md) {
    margin-top: 32px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 63px;

    .title {
      padding-top: 43px;
    }
    &__logo {
      height: auto;
      margin-bottom: 20px;
    }
    &__info {
      padding-top: 17px;
    }
    &__row {
      display: block;
      margin: 0;
      padding-top: 17px;
    }
    &__title {
      margin-bottom: 0;
    }
    &__item {
      padding: 0;
    }
    &__item:not(:last-child) {
      margin-bottom: 90px;
    }
  }
}