html, body {
  height: 100%;
}
body {
  padding-top: 80px;
}
a {
  transition: all .3s;
  color: #020303;
  &:hover {
    color: #a2cd1d;
  }
}

h1, .h1, h2, .h2, h3, .h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}

.h2 {
  margin-bottom: 40px;
  text-transform: uppercase;
  span {
    display: block;
    margin-top: 6px;
    font-size: 20px;
    font-weight: 500;
    text-transform: initial;
  }
  
  &--char {
    margin-bottom: 0;
  }

  @include media-breakpoint-down(md) {
    text-align: center;
  }
}

p {
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 24px;
}

.container {
  padding-left: 104px;
  padding-right: 104px;

  @include media-breakpoint-down(xl) {
    padding-left: 20px;
  }
  @include media-breakpoint-down(lg) {
    padding-right: 20px;
  }
}

// .container--lt {
//   max-width: 750px;
// }


@include media-breakpoint-down(md) {
  .mb-md-down-0 {
    margin-bottom: 0 !important;
  }
  .mt-md-down-0 {
    margin-top: 0 !important;
  }

  .pb-md-down-0 {
    padding-bottom: 0 !important;
  }
  .pt-md-down-0 {
    padding-top: 0 !important;
  }
}

.ui-text {
  font-size: 20px;
  line-height: 1.1755;
  
  @include media-breakpoint-down(xl) {
    font-size: 16px;
  }
}

.ui-bg-dark {
  background-color: #020303;
}

.btn {
  padding: 11px 12px;
  color: #000;
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
  border-radius: 0;
  border: 1px solid transparent;

  @include media-breakpoint-down(sm) {
    &__text {
      font-size: 14px;
    }
  }
}

.btn-primary {
  background-color: #feda34;
  border-color: #feda34;

  &:hover {
    background-color: #edc42d;
    border-color: #edc42d;
  }
}

.btn-secondary {
  background-color: var(--bs-secondary);
  border-color: var(--bs-secondary);
  color: #fff;

  &:hover {
    background-color: #149900;
    color: #fff;
  }
}
.btn-ozon {
  background-color: #005BFF;
  border-color: #005BFF;
  color: #fff;
  &:hover {
    border-color: #1a6cff;
    background-color: #1a6cff;
    color: #fff;
  }
}
.btn-wildberries {
  background-color: #a73afd;
  border-color: #a73afd;
  color: #fff;
  &:hover {
    border-color: rgba(167,58,253,.9);
    background-color: rgba(167,58,253,.9);
    color: #fff; 
  }
}
.title {
  margin-bottom: 40px;
  padding-top: 60px;
  font-size: 40px;
  text-align: center;
  text-transform: uppercase;
  line-height: 1.25;
  font-weight: 700;

  span {
    font-size: 20px;
  }

  @include media-breakpoint-down(sm) {
    margin-bottom: 30px;
    padding-top: 40px;
    // font-size: 6.4vw;
    font-size: 27px;
  }
}

.subtitle {
  margin-bottom: 40px;
  font-size: 25px;
  text-align: center;

  @include media-breakpoint-down(lg) {
    margin-bottom: 20px;
    font-size: 20px;
  }
}

.h1-title, 
.h2-title, 
.title h1 {
  margin: 0;
  padding: 0;
  font-size: 40px;

  @include media-breakpoint-down(lg) {
    font-size: 25px;
  }
}

.read-more {
  font-size: 14px;
  display: block;
}

.one-star {
  width: 23px;
  height: 24px;
  background: url('../../assets/img/content/ic/star.svg');
}

.img {
  display: block;
  max-width: 100%;
  margin: 0 auto;
}
.img--top {
  margin-top: -40px;

  @include media-breakpoint-down(lg) {
    margin-top: 0;
  }
}

.bg-gray {
  background-color: #f9f9f9;
}

.text {
  margin-bottom: 50px;
  font-size: 25px;
  line-height: 1.333;

  &--lg {
    margin-bottom: 65px;
    font-size: 30px;
  }
  
  @include media-breakpoint-down(md) {
    &--lg {
      font-size: 27px;
    }
  }
  
  @include media-breakpoint-down(sm) {
    font-size: 20px;

    &--lg {
      margin-bottom: 40px;
      font-size: 20px;
    }
  }
}

.dark-block {
  background-color: #000;

  .title {
    padding-top: 119px;

    &--offset {
      padding-top: 50px;
    }
  }

  .title,
  .text {
    position: relative;
    color: #fff;
    z-index: 2;
  }
}

.main-link {
  display: block;
  font-size: 25px;
  text-align: center;
  text-decoration: underline;
  color: var(--bs-black);

  @include media-breakpoint-down(sm) {
    font-size: 20px;
  }
}

.img-car {
  margin-top: 24px;
}



@include media-breakpoint-down(md) {
  .sprinter, 
  .img-engine, 
  .img-car, 
  .car2 {
    max-width: 100%;
    left: 0;
    transform: translateX(0);
  }
}


@include media-breakpoint-down(sm) {
  .img-engine-wrap {
    overflow: hidden;
  }
  .sprinter, 
  .img-car {
    height: 300px;
    object-position: 90%;
    left: -20px;
  }
  .img-car {
    position: relative;
    left: -16px;
  }
  .sprinter, 
  .img-engine, 
  .img-car {
    object-fit: cover;
  }
}

.img-engine {
  position: relative;
  display: block;
  margin-top: -80px;
  text-align: center;

  + .title--offset {
    padding-top: 120px;
  }

  @include media-breakpoint-down(lg) {
    margin-top: -40px;
  }

  @include media-breakpoint-down(sm) {
    height: auto;
    min-width: 600px;
    left: calc(50% + 40px);
    margin-top: 0;
    transform: translate(-50%, -8%);
    object-position: center;
  }
}

.arrow-circle {
  display: block;
  max-width: 100%;
  margin: 0 auto;
}

.b-action {
  margin-top: -8px;
  margin-bottom: 6px;
  padding: 5px 0;
  background-color: var(--bs-yellow);
  text-align: center;
  font-size: 16px;
  font-weight: bold;

  @include media-breakpoint-down(sm) {
    padding: 7px 0;
    font-size: 13px;
  }
}

.tab-list {
  &__title {
    font-size: 22px;
    font-weight: 700;
  }

  &__ico {
    flex: none;
    width: 45px;
    margin-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      max-width: 100%;
      max-height: 50px;
    }
  }

  &__text {
    font-size: 20px;
  }
  li {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &--light {
    margin-top: 28px;

    li {
      margin-bottom: 16px;
    }

    .tab-list__text {
      padding-left: 20px;
    }
  }

  @include media-breakpoint-down(md) {
    &__text {
      font-size: 18px;
      padding-right: 15px; 
    }

    &__ico {
      width: 40px;
      margin-right: 15px; 
    }
  }

  @include media-breakpoint-down(sm) {
    &__title {
      text-align: center;
      justify-content: center;
    }

    &--light {
      .tab-list__text {
        padding-left: 0;
      }
    }
  }
}

.custom-tooltip {
  --bs-tooltip-bg: #f3f3f3;
  --bs-tooltip-color: #000000;
  --bs-tooltip-max-width: 280px; 
  --bs-tooltip-opacity: 1;
  filter: drop-shadow(0px 4px 3px rgba(0, 0, 0, 0.16));
  font-size: 11px;
}

.view {
  margin-bottom: 40px;
  display: flex;

  li {
    font-size: 18px;
    margin-right: 45px;

    &:last-child {
      margin-right: 0;
    }

    a {
      color: #000;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    li {
      margin-right: 30px;
    }
  }
  @include media-breakpoint-down(md) {
    li {
      margin-right: 5%;
      margin-bottom: 15px;
    }
  }

  @include media-breakpoint-down(sm) {
    li {
      margin-right: 15px;
    }
  }
}

.model-page li {
  font-size: 18px;
  line-height: 24px;
}

.notice {
  display: block;
  color: #797979;
  font-size: 16px;
}

.accordion-button:not(.collapsed) {
  background-color: #ffe863;
}
.accordion-item {
  font-size: 1rem;
}

.text-mb {
    margin-bottom: 25px;
    font-size: 20px;
    line-height: 1.33;

    @include media-breakpoint-down(md) {
      font-size: 18px;
    }
}
.text-mb a, 
.text-lt a {
    color: var(--bs-blue);
    text-decoration-color: var(--bs-blue);
    &:hover {
      text-decoration-color: transparent;
    }
}

.form-control-2 {
  display: block;
  width: 100%;
  padding: 10px 30px 10px 10px;
  color: #010101;
  font-size: 16px;
  font-weight: 500;
  background-color: #fff;
  text-transform: uppercase;
  border: 1px solid #5e5e5e;
  box-shadow: none;
  border-radius: 0;
  outline: none;
  text-transform: none;
  text-align: left;
  appearance: none;
}

@include media-breakpoint-up(md) {
  .payment-page .text-mb, 
  .delivery-page .text-mb {
      font-size: 30px;
  }
  .testdrive-page .text-mb {
    font-size: 25px;
  }
}

.form {
  &__header {
    padding-bottom: 35px;
    font-size: 30px;
  }
}

.ui-select {
  border-color: #fff;
}

.ui-select-2 {
  padding-right: 10px; 
  font-weight: 500;
}
.bootstrap-select .dropdown-menu li a span.text {
  margin-bottom: 0;
  font-size: 16px;
}

.motorwarranty-page .success {
  display: none;
  padding-top: 50px;
  color: #ffdd00;
  font-size: 22px;
}

.ui-carousel {  
  .slick-arrow {
    position: absolute;
    top: 50%;
    width: 18px;
    height: 30px;
    transform: translateY(-50%);
    transition: all .3s;
    background: url(../../assets/img/plugins/prev-arrow.png);
    z-index: 5;
    &:hover,
    &:focus {
      opacity: 0.6;
      background: url(../../assets/img/plugins/prev-arrow.png);
    }
  }
  .slick-prev {
    left: -40px;
  }
  .slick-next {
    right: -40px;
    transform: rotate(180deg) translateY(50%);
  }


  @include media-breakpoint-down(xl) {
    .slick-prev {
      left: -30px;
    }
    .slick-next {
      right: -30px;
    }
  }
}

.bootstrap-select > .dropdown-toggle::after {
  flex: 0 0 auto;
  width: 31px;
  height: 16px;
  margin: 0;
  border: none;
  background: url('data:image/svg+xml,<svg width="31" height="16" viewBox="0 0 31 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.5 1L15.5 15" stroke="%23848484" stroke-width="2" stroke-linecap="round"/><path d="M29.5 1L15.5 15" stroke="%23848484" stroke-width="2" stroke-linecap="round"/></svg>');
}

.ui-table {
  th {
    font-size: 28px;
  }
  th,
  td {
    padding: 20px 30px;
    border-color: #fff;
  }
  td {}

  &.table-dark {
    a {
      
    }
  }


  @include media-breakpoint-down(xl) {
    th {
      font-size: 18px;
    }
    th,
    td {
      padding: 20px;
    }
  }

  @include media-breakpoint-down(md) {
    th {
      padding: 16px 18px;
    }
    td {
      padding: 9px 18px;
    }
  }
}

.table-dark {
  a {
    color: #fff;
    text-decoration: underline;
    &:hover {
      color: var(--bs-secondary);
    }
  }
}
.table-dark.table-bordered > :not(caption) > * {
  border-color: #fff;
}

.tab {
  margin-bottom: 15px;

  @include media-breakpoint-down(md) {
    margin-bottom: 40px;
  }
}

.ui-title {
  margin-bottom: 20px;
  font-size: 28px;
  font-weight: 700;
  line-height: 1.175;

  @include media-breakpoint-down(md) {
    font-size: 18px;
  }
}

.ui-list-mark {
  margin-bottom: 0;
  padding-left: 0;
  list-style-type: none;
  font-size: 20px;
  line-height: 1.175;

  li {
    position: relative;
    margin-bottom: 32px;
    padding-left: 26px;
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 3px;
      width: 16px;
      height: 13px;
      background: url('data:image/svg+xml,<svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.7373 7.24512L5.33703 9.93579" stroke="%23FFDB0A" stroke-width="3" stroke-linecap="square" stroke-linejoin="round"/><path d="M5.33691 9.93641L12.9921 2.28125" stroke="%23FFDB0A" stroke-width="3" stroke-linecap="square" stroke-linejoin="round"/></svg>');
    }
  }

  @include media-breakpoint-down(md) {
    font-size: 16px;
  }
}

.ui-text-default {
  font-size: 20px;
  line-height: 1.175;

  p {
    margin-bottom: 40px;
    font-size: 20px;
    line-height: 1.175;
  }

  @include media-breakpoint-down(md) {
    font-size: 16px;

    p {
      font-size: 16px;
    }
  }
}


#wa-btn-wrapper {
  position: fixed;
  z-index: 999999;
  bottom: 20px;
  right: 20px;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

#wa_btn-content {
  box-sizing: border-box;
  display: inline-flex;
  padding: 10px 14px;
  justify-content: center;
  align-items: center;
  color: #fff;
  cursor: pointer;
  background: #16BE45;
  border-radius: 40px;
  
  p {
    margin-left: 8px;
    margin-bottom: 0;
    font-size: 14px;
    line-height: 20px;
    color: white;
  }
}

@media(max-width:499px) {
  #wa_btn-content p {
    display: none;
  }
}

.form-control-2:not(.is-invalid):valid,
.form-control:not(.is-invalid):valid {
  background-image: url(../../assets/img/content/ic/circle-green.svg);
  background-repeat: no-repeat;
  background-position: calc(100% - 15px) 50%;
  background-size: 11px;
}


#preloader{
  display: table;
  position: fixed;
  z-index: 9999;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, .9);
  overflow: hidden;
}
#spinner{
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
#spinner img{
  max-width: 72px;
}

.cooks-container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #959595;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 13px;
  color: #fff;
  line-height: 1.3;
  padding: 8px 15px 8px;
  z-index: 1001;

  p {
    color: #fff;
    margin-bottom: 0;
    font-size: 14px;
  }
  a {
    color: #fff;
    text-decoration: underline;
    &:hover{
      text-decoration: none;
    }
  }
}

.close-cook {
  flex: none;
  display: block;
  width:16px; 
  height:16px;
  top: 0;
  position: relative;
  background: url('../img/content/ic/close-white.png') 0 0 no-repeat;
  background-size: contain; 
  margin-left: 60px;
}
@media(max-width: 767px) {
  .cooks-container {
    align-items: flex-start;
  }
  .close-cook {
    margin-left: 10px;
    right: -10px;
  }
}
@media(max-width: 340px) {
  .cooks-container{
    font-size: 14px;
    padding: 20px 20px 17px;
  }
}
.cookie-text-page ul ul li {
  margin-left: 10px;
  margin-top: 10px;
}
.cookie-text-page li {
  font-size: 18px;
}  