
.garantie-block{
  background:transparent url('../../assets/img/content/b-partners/bg-car.jpg') center top no-repeat;
  background-size: cover;
  height: 766px;
}
.garantie-block .title-text{
  margin-bottom: 25px;
}

.team-block{
  margin-bottom: 130px;
}
.team-block .main-text{
  margin-bottom: 60px;
}
.team-img{
  display: block;
  width: 100%;
  margin-bottom: 20px;
  text-align: center;
}
.team-img:last-child{
  margin-bottom: 0;
}

.main-text {
    font-size: 25px;
    line-height: 1.4;
}

.top-block {
  margin-bottom: 130px;
  padding-top: 80px;
  
  .title {
    margin-bottom: 70px;
    padding-top: 0;
  }
}
.top-block-descr {
  margin-top: 130px;

  &__item {
    ul {
      margin-bottom: 0;

      li {
        font-size: 24px;
        margin-bottom: 25px;
        line-height: 1.3;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  &__title {
    font-size: 40px;
    text-transform: uppercase;
    font-weight: 700;
    color: #000;
    line-height: 1.375;
    margin-bottom: 20px;
  }
}

.top-block-descr__item.light ul {
  color: #7e7e7e;
}

.case {
  height: 768px;
  background-size: cover;
  background-position: 50% 0;
}
.case-1 {
  background: url('../../assets/img/content/b-partners/bg-man.jpg');
}
.case-2 {
  background: url('../../assets/img/content/b-partners/bg-flat.jpg');
}
.case-3 {
  background: url('../../assets/img/content/b-partners/bg-diller.jpg');
}
.case-4 {
  background: url('../../assets/img/content/b-partners/bg-car2.jpg');
}
.case-5{
  background: url('../../assets/img/content/b-partners/bg-notebook.jpg');
}
.case .container {
  height: 100%;
}
.case-body {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

.notice-text {
  padding: 120px 0;
  font-size: 38px;
  line-height: 1.36;
  text-align: center;
}


.work-conditions{
  margin: -15px auto 100px;
  max-width: 700px;
}
.work-conditions li {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 30px;
}
.work-conditions li:last-child {
  margin-bottom: 0;
}
.work-conditions li .num {
  font-size: 68px;
  color: #b1b1b1;
  width: 43px;
  text-align: center;
}
.work-conditions li .descr-text{
  padding-left: 25px;
  font-size: 25px;
  width: calc(100% - 45px);
}

.contact-form{
  width: 100%;
  max-width: 400px;
  margin: -10px auto 0;
}


.contact-admin{
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 800px;
  margin: 0 auto 140px;
}
.contact_admin__photo{
  width: 40%;
}

.contact_admin__data{
  width: 60%;
  height: 100%;
  padding: 30px 0 30px 70px;
}
.contact_admin__data a{
  text-decoration: none;
  color: #000;
}
.admin__name{
  font-size: 30px;
  line-height: 1.2;
  margin-bottom: 35px;
}
.admin__name span{
  display: block;
}
.admin__data__tel{
  margin-bottom: 35px;
}
.admin__data__tel a{
  white-space: nowrap;
}
.admin__data__tel,
.admin__data__mail{
  font-size: 25px;
}

.admin__data__mail a{
  text-decoration: underline;
  white-space: nowrap;
}
.admin__data__mail a:hover{
  text-decoration: none;
}

@include media-breakpoint-down(lg) {
  .contact-to-work {
    padding-top: 60px;
  }
  .top-block-descr__title, 
  .top-block .title {
    font-size: 25px;
  }
  .team-block .title-text, 
  .team-block .main-text {
    padding: 0 20px;
  } 
  .title-text {
    padding: 0 40px;
  }
  .title-text, 
  .top-block-descr__title, 
  .top-block .title {
    font-size: 30px;
  }

  .top-block .title{
    margin-bottom: 20px;
  }
  .top-block{
    padding-top: 60px;
    margin-bottom: 80px;
  }

  .garantie-block + .container{
    padding: 0;
  }
  .garantie-block, .case{
    height: auto;
    min-height: 550px;
    padding: 120px 0;
  }

  .notice-text{
    padding: 80px 0;
    font-size: 35px;
  }
  .team-block, 
  .garantie-block {
    margin-bottom: 60px;
  }
  .garantie-block, 
  .case {
    position: relative;
    height: auto;
    min-height: 550px;
    padding: 120px 0;
    background-size: inherit;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, .5);
    }
  }
  .garantie-block .container, 
  .case .container {
    position: relative;
    z-index: 1;
  }
}

@include media-breakpoint-down(md) {
  .work-conditions .contact-form{
    margin-top: 10px;
  }
  .contact-admin {
    flex-direction: column;
    margin-bottom: 70px;
  }
  .contact_admin__photo{
    width: 100%;
    max-width: 320px;
  }
  .contact_admin__data{
    width: 100%;
    max-width: 320px;
    margin: 0 auto;
    padding-left: 0;
    padding-top: 60px;
  }

  .notice-text{
    padding: 50px 0;
    font-size: 30px;
  }
  .garantie-block, .case {
    padding: 30px 0;
  }
  .garantie-block .title-text {
    margin-bottom: 25px;
  }
  .top-block-descr__item {
    width: 100%;
    margin-bottom: 50px;
  }
  .top-block-descr__item:last-child {
    margin-bottom: 0;
  }
}

@include media-breakpoint-down(sm) {
  .title-text.title {
    padding-top: 40px;
  }
}