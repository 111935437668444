.contact-block {
  &__title {
    margin-bottom: 25px;
    font-size: 30px;
  }

  @include media-breakpoint-down(md) {
    &__title {
      font-size: 22px;
    }
  }
}


.contact-block {
  margin-bottom: 30px;

  &__title {
    font-size: 30px;
    margin-bottom: 25px;
  }
}

.contact__item {
  margin-bottom: 30px;
  &__title {
    font-size: 25px;
    margin-bottom: 15px;
  }

  .text {
    margin-bottom: 20px;
    font-size: 20px;
    line-height: 27px;

    &:last-child {
      margin-bottom: 0;
    }

    a {
      color: var(--bs-blue);
      position: relative;
      text-decoration: none;

      &::after {
        content: '';
        width: 100%;
        height: 1px;
        background-color: var(--bs-blue);
        position: absolute;
        left: 0;
        bottom: -2px;
        transition: all 0.2s;
      }

      &:hover::after {
        opacity: 0;
      }
    }
  }

  &__map {
    height: 0;
    overflow: hidden;
    display: block;

    &.active {
      height: auto;
    }
  }
}
