.footer {
  width: 100%;
  margin: 50px 0 0 0;
  background-color: #ebebeb;
  padding: 65px 0;

  &__main {
    display: flex;
    justify-content: space-between;
    padding-bottom: 40px;

    &__imgs {
      display: flex;
      align-items: center;

      .ic {
        width: 90px;
        margin-right: 10px;
      }
    }

    &__item {
      width: 22%;
      p {
        font-size: 14px;
        line-height: 1.2;
        color: #000;
        a {
          color: #000;
          text-decoration: underline;
          &:hover {
            text-decoration: none;
            color: var(--bs-green);
          }
        }
      }
    }
    &__title {
      margin-bottom: 12px;
      font-weight: 700;
      font-size: 16px;
    }
  }
  &__copyright {
    border-top: 1px solid #d7d7d7;
    border-bottom: 1px solid #d7d7d7;
    padding: 30px 0;
    text-align: center;
    color: #565555;
    font-size: 18px;
  }

  &__model {
    padding-top: 60px;
  }

  &__mode__title {
    margin-bottom: 35px;
    text-align: center;
    color: #000;
    font-size: 16px;
  }

  &__marks {
    columns: 6;
  }

  &__marks-group {
    margin-bottom: 20px;
    page-break-inside: avoid;
  }

  &__marks-item {
    a {
      color: #000;
      font-size: 14px;
      line-height: 1.2;
      text-decoration: none;
      &:hover {
        color: var(--bs-green);
      }
    }
    &:not(:last-child) {
      margin-bottom: 0;
    }
  }

  @include media-breakpoint-down(lg) {
    padding: 35px 0 80px;

    &__main {
      flex-wrap: wrap;
      padding-bottom: 10px;
    
      &__item {
        width: 45%;
        margin-bottom: 25px;
        text-align: left;
      }
    }

    &__model{
      padding-top: 30px;
    }
  }

  @include media-breakpoint-down(md) {
    &__marks {
      columns: 4;
    }
    &__mode__title {
      display: none;
    }
  }

  @include media-breakpoint-down(sm) {
    &__marks {
      columns: 3;
    }
    &__main {
      display: block;
      &__item {
        width: 100%;
        margin-bottom: 40px;
      }
    }
  }
}





.foot-menu {
  li {
    a {
      color: #000;
      text-decoration: none;
      font-size: 16px;
      line-height: 1.8;
      &:hover {
        color: var(--bs-green);
      }
    }
  }
}

.foot-socio {
  display: flex;
  justify-content: space-between;
  width: 90px;
  margin-bottom: 25px;
  .ic {
    max-width: 100%;
    width: 38px;
    height: 38px;
  }
}

.foot-link {
  &.btn {
    min-width: 180px;
  }
}

.foot-img {
  display: block;
  margin-bottom: 5px;
  max-width: 100%;
}


.foot-contact {
  display: block;
  color: #000;
  margin-bottom: 15px;
  font-size: 17px;
  text-decoration: none;
  line-height: 1;

  &:hover {
    text-decoration: none;
    color: var(--bs-green);
  }

  &[href^='tel'] {
    font-size: 20px;
  }

  &[href^='mailto'] {
    text-decoration: underline;
  }
}



.foot-notice {
  max-width: 820px;
  padding: 0 20px 50px;
  margin: 0 auto;
 
  p {
    font-size: 12px;
    line-height: 1.5;
    &:not(:last-child) {
      margin-bottom: 22px;
    }
  }


  &.anno-1-text {
    display:none;
  }
}

.page-w-note {
  .anno-1-text {
    display: block;
  }
}

.foot-language {
  display: flex;
  align-items: center;
  font-size: 16px;
  // color: #717171;
  text-decoration: none;
  &:hover {
    text-decoration: none;
    color: var(--bs-green);
  }
}