
.thankyou-page #menus{
    position: static;
    background: black;
}


.thankyou h1{
  font-size: 25px;
  padding: 20px 0;
}

.thankyou .small{
  font-size: 16px;
  line-height: 16px;
  color: black;
}
.thankyou-main {
  padding-top: 20px;
  padding-bottom: 21px;
  border-bottom: 1px solid rgba(2, 3, 3, .1);
}
.thankyou-order{
  font-size: 22px;
  padding: 20px 0;
  padding-top: 10px;
}
.thankyou-order strong {
  font-size: 25px;
  font-weight: 700;
}
 .thankyou-descrip {
  padding-top: 20px;
  padding-bottom: 18px;
  border-bottom: 1px solid rgba(2, 3, 3, .1);
}
.thankyou-descrip__title {
  margin-bottom: 14px;
  font-size: 25px;
}
.thankyou-descrip__item {
  margin-bottom: 6px;
}
.thankyou-descrip__price {
  font-weight: bold;
  color: #a2cd1d;
  white-space: nowrap;
}

.thankyou-order-info, 
.thankyou-order-payment{
  font-size: 22px;
  margin-bottom: 20px;
}
.thankyou-main__title {
  margin-bottom: 10px;
  font-size: 25px;
  line-height: 150%;
  font-weight: bold;
}
.thankyou-order-sum{
  font-size: 22px;
  margin-bottom: 20px;
}
.thankyou-order-sum strong {
  font-size: 45px;
}

.thankyou-order-links__item:not(:last-child) {
  margin-bottom: 20px;
}
.thankyou-order-links__item a {
  color: #000;
  text-decoration: underline;
  font-size: 18px;
}

.thankyou-img {
  margin-top: 120px;
  text-align: center;
}

.thankyou .pay-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 255px;
  height: 57px;
  font-size: 22px;
}

@media (max-width: 768px){
  .thankyou-img {
    padding: 0 30px;
  }
}
