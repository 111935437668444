.oferta p:last-child {
  margin-bottom: 0;
}
.oferta-item {
  margin-bottom: 40px;
}


@include media-breakpoint-up(md) {
  .oferta-item {
    margin-bottom: 60px;
  }
  .oferta p {
    font-size: 20px;
    line-height: 27px;
    margin-bottom: 20px;
  }
}