.gan-column--offset_top {
  margin-top: 70px;
}

.gan {
  &__item {
    position: relative;
    width: 48%;
    max-width: 610px;
    
    &--flex {
      // width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      .gan__img {
        img {
          display: block;
          margin: 0 auto;
        }
      }
    }

    &.disabled {
      user-select: none;

      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        top: 0;
        background-color: #FFF;
        opacity: 0.5;
        z-index: 10;
      }

      .product-price,
      .buy-block {
        opacity: 0;
      }
    }

    &--center {
      .h2,
      .text-center {
        text-align: center;
      }
      .h2 {
        margin-bottom: 15px;
      }
      .hg {
        margin-top: 15px;
        height: 60px;
        font-size: 30px;
        font-weight: 500;
        text-align: center;
      }
      .gan__img {
        margin-bottom: 25px;
      }
    }

    p {
      font-size: 18px;
      line-height: 1.2;
      margin-bottom: 35px;

      &.text--lg {
        margin-bottom: 25px;
      }
    }

    .text--lg {
      margin-bottom: 25px;
    }
  }

  &__item.gan__item--center p {
    font-size: 25px;
    margin-bottom: 25px;
  }

  &__desrip-trigger { 
    position: relative;
    top: -4px;
    margin-bottom: 15px;
    padding: 0;
    border: none;
    border-bottom: 1px solid #dcebb2;
    font-size: 17px;
    font-weight: 700;
    color: #a3cb33;
    background-color: transparent;
    text-transform: uppercase;
    letter-spacing: .04em;
    line-height: 1;

    &:after {
      content: '';
      position: absolute;
      left: -18px;
      top: 2px;
      display: inline-block;
      width: 11px;
      height: 11px;
      border-bottom: 2px solid #a3cb33;
      border-left: 2px solid #a3cb33;
      transform: rotate(-45deg);
      border-radius: 2px;
    }

    &:focus {
      outline: none;
    }

    &.active:after {
      top: 4px;
      transform: rotate(135deg);
    }
  }

  &__img {
    text-align: center;

    img {
      max-width: 100%;
    }

    &.container--lt {
      padding: 40px 0;
    }
  }

  &__img__data {
    display: flex;
    justify-content: center;
    margin-top: -44px;

    & span {
      display: inline-block;
      padding: 0 20px;
      vertical-align: bottom;
    }

    & strong {
      font-weight: 700;
      font-size: 60px;
    }

    & small {
      position: relative;
      display: inline-block;
      font-size: 25px;

      &.anno-1::after {
        content: '1';
        font-size: 16px;
        display: inline-block;
        margin-left: 3px;
        vertical-align: top;
      }
    }
  }


  @include media-breakpoint-down(xl) {
    &__item {
      max-width: 400px;
    }
  }


  @include media-breakpoint-only(md) {    
    &__item {
      .view {
        justify-content: space-between;
        li {
          margin-right: 0;
        }
      }
    }
  }

  @include media-breakpoint-only(xl) {
    &__item {
      .left-offset2 {
        padding-left: 50px;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    &__item, 
    &__item--center {
      width: 100%;
      max-width: calc(50% - 15px);
    }
    &__img__data strong {
      font-size: 40px;
    }
  }

  @include media-breakpoint-down(md) {
    &__item, 
    &__item--center {
      max-width: 100%;
    }
    &__item {
      &--center {
        .hg {
          height: auto;
          font-size: 24px;
        }
      }
    }

    &__group {
      display: flex;
      flex-direction: column-reverse;
      
      .gan__mob-hid {
        height: 0;
        margin-bottom: 0;
        overflow: hidden;
        transition: all .3s;
      }
      &.active {
        .gan__mob-hid {
          height: auto;
          margin-bottom: 20px;
        }

        .tab-content {
          &:after {
            content: none;
          }
        }
      }

      .tab-content {
        position: relative;
        &:after {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 75px;
          background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, .6) 40%, rgba(255, 255, 255, 1) 100%);
          content: '';
        }
      }
    }
  }
}


.gan-column--ga {
  .gan__img__data {
    margin-top: 20px;
    justify-content: center;
    width: 100%;
  }
}

.main-page {
  .gan {
    &__img{
      height: 390px;
      display: flex;
      align-items: flex-end;
      justify-content: center;
    }

    &__item--center{
      padding-top: 120px;
    }
  }

  @include media-breakpoint-down(lg) {
    .gan {
      &__item--center {
        padding-top: 60px;
      }
    }
  }

  @include media-breakpoint-down(md) {
    .gan {
      &__img {
        height: auto;
        margin-top: 25px;
      }
    }
  }
}


.product-price {
  display: flex;
  flex-direction: column;
  line-height: 1.2;
  min-height: 98px;
  justify-content: flex-end;

  &--lt {
    min-height: 68px;
  }
}


.single-product {
  &__img {
    width: 100%;
    max-width: 500px;

     img {
      width: 100%;
    }
  }
  
  &__price {
    .h2 {
      margin: 0;
    }
    .text{
      margin-bottom: 25px;
    }
  }

  @include media-breakpoint-down(lg) {
    &__price {
      .product-price {
        text-align: center;
      }
      .h2 {
        text-align: left;
        margin-top: 25px;
      }
      .economy-block {
        justify-content: center;
      }
      .buy-block {
        align-items: center;
      }
    }
  }

  @include media-breakpoint-down(md) {
    &__price {
      &.gan__item--buy {
        .product-price {
          text-align: left;
        }
        .economy-block{
          justify-content: flex-start;
        }
      }
    }
  }
}

.left-offset {
  position: relative;
  left: 55px;
}

.left-offset2 {
  padding-left: 65px;
}

.left-offset3 {
  position: relative;
  left: 25px;
}

@include media-breakpoint-down(md) {
  .left-offset, 
  .left-offset3 {
    left: 0;
  }
  .left-offset2 {
    padding-left: 0;
  }
}

.ruble {
  position: relative;
  z-index: 2;

  &::after {
    content: '\20BD';
    display: inline-block;
    padding-left: 0.4em;
  }
}

.old-price {
  font-size: 20px;
  position: relative;
  display: table;
  flex: none;
  letter-spacing: 1px;
  text-decoration-line: line-through;
}

.current-price {
  font-size: 35px;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 1.5px;
}

.economy-block {
  display: flex;
  align-items: center;

  .economy-sum {
    display: block;
    padding: 5px 7px 3px 7px;
    line-height: 1;
    border: 1px solid #a2cd1d;
    margin-right: 9px;
  }

  .economy-text {
    font-size: 17px;
  }
}

.buy-block {
  display: flex;
  margin: 10px 0;

  &__item {
    padding-left: 5px;
    padding-right: 5px;
  }

  &__text {
    line-height: 1.4;
    padding-top: 5px;
    font-size: 15px;

    span {
      display: block;
    }
  }

  .btn {
    min-width: 210px;
  }

  @include media-breakpoint-down(lg) {
    display: block;
    &__item {
      margin-top: 25px;
    }
  }

  @include media-breakpoint-down(md) {
    .btn {
      margin-bottom: 10px;
    }
  }
}

.wrap-price {
  .economy-block {
    margin-left: 13px;

    @include media-breakpoint-down(lg) {
      margin-left: 0;
    }
  }
}

.gtl-data_link {
  padding: 0;
  border: none;
  background-color: transparent;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}

.buy-single-product {
  max-width: 960px;
  margin: 90px auto;

  @include media-breakpoint-down(lg) {
    flex-direction: column;
    margin: 50px 0;
  }
}