// @font-face {
//     font-family: 'Gilroy';
//     src: url('../fonts/Gilroy-Regular.eot');
//     src: local('Gilroy Regular'), local('Gilroy-Regular'),
//         url('../fonts/Gilroy-Regular.eot?#iefix') format('embedded-opentype'),
//         url('../fonts/Gilroy-Regular.woff2') format('woff2'),
//         url('../fonts/Gilroy-Regular.woff') format('woff'),
//         url('../fonts/Gilroy-Regular.ttf') format('truetype');
//     font-weight: normal;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'Gilroy';
//     src: url('../fonts/Gilroy-ExtraboldItalic.eot');
//     src: local('Gilroy Extrabold Italic'), local('Gilroy-ExtraboldItalic'),
//         url('../fonts/Gilroy-ExtraboldItalic.eot?#iefix') format('embedded-opentype'),
//         url('../fonts/Gilroy-ExtraboldItalic.woff2') format('woff2'),
//         url('../fonts/Gilroy-ExtraboldItalic.woff') format('woff'),
//         url('../fonts/Gilroy-ExtraboldItalic.ttf') format('truetype');
//     font-weight: 800;
//     font-style: italic;
// }

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy Bold'), local('Gilroy-Bold'),
        url('../fonts/Gilroy-Bold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
}

// @font-face {
//     font-family: 'Gilroy';
//     src: url('../fonts/Gilroy-Black.eot');
//     src: local('Gilroy Black'), local('Gilroy-Black'),
//         url('../fonts/Gilroy-Black.eot?#iefix') format('embedded-opentype'),
//         url('../fonts/Gilroy-Black.woff2') format('woff2'),
//         url('../fonts/Gilroy-Black.woff') format('woff'),
//         url('../fonts/Gilroy-Black.ttf') format('truetype');
//     font-weight: 900;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'Gilroy';
//     src: url('../fonts/Gilroy-Light.eot');
//     src: local('Gilroy Light'), local('Gilroy-Light'),
//         url('../fonts/Gilroy-Light.eot?#iefix') format('embedded-opentype'),
//         url('../fonts/Gilroy-Light.woff2') format('woff2'),
//         url('../fonts/Gilroy-Light.woff') format('woff'),
//         url('../fonts/Gilroy-Light.ttf') format('truetype');
//     font-weight: 300;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'Gilroy';
//     src: url('../fonts/Gilroy-Semibold.eot');
//     src: local('Gilroy Semibold'), local('Gilroy-Semibold'),
//         url('../fonts/Gilroy-Semibold.eot?#iefix') format('embedded-opentype'),
//         url('../fonts/Gilroy-Semibold.woff2') format('woff2'),
//         url('../fonts/Gilroy-Semibold.woff') format('woff'),
//         url('../fonts/Gilroy-Semibold.ttf') format('truetype');
//     font-weight: 600;
//     font-style: normal;
// }

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy Medium'), local('Gilroy-Medium'),
        url('../fonts/Gilroy-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
}

// @font-face {
//     font-family: 'Gilroy';
//     src: url('../fonts/Gilroy-MediumItalic.eot');
//     src: local('Gilroy Medium Italic'), local('Gilroy-MediumItalic'),
//         url('../fonts/Gilroy-MediumItalic.eot?#iefix') format('embedded-opentype'),
//         url('../fonts/Gilroy-MediumItalic.woff2') format('woff2'),
//         url('../fonts/Gilroy-MediumItalic.woff') format('woff'),
//         url('../fonts/Gilroy-MediumItalic.ttf') format('truetype');
//     font-weight: 500;
//     font-style: italic;
// }

// @font-face {
//     font-family: 'Gilroy';
//     src: url('../fonts/Gilroy-BlackItalic.eot');
//     src: local('Gilroy Black Italic'), local('Gilroy-BlackItalic'),
//         url('../fonts/Gilroy-BlackItalic.eot?#iefix') format('embedded-opentype'),
//         url('../fonts/Gilroy-BlackItalic.woff2') format('woff2'),
//         url('../fonts/Gilroy-BlackItalic.woff') format('woff'),
//         url('../fonts/Gilroy-BlackItalic.ttf') format('truetype');
//     font-weight: 900;
//     font-style: italic;
// }

// @font-face {
//     font-family: 'Gilroy';
//     src: url('../fonts/Gilroy-UltraLight.eot');
//     src: local('Gilroy UltraLight'), local('Gilroy-UltraLight'),
//         url('../fonts/Gilroy-UltraLight.eot?#iefix') format('embedded-opentype'),
//         url('../fonts/Gilroy-UltraLight.woff2') format('woff2'),
//         url('../fonts/Gilroy-UltraLight.woff') format('woff'),
//         url('../fonts/Gilroy-UltraLight.ttf') format('truetype');
//     font-weight: 200;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'Gilroy';
//     src: url('../fonts/Gilroy-RegularItalic.eot');
//     src: local('Gilroy Regular Italic'), local('Gilroy-RegularItalic'),
//         url('../fonts/Gilroy-RegularItalic.eot?#iefix') format('embedded-opentype'),
//         url('../fonts/Gilroy-RegularItalic.woff2') format('woff2'),
//         url('../fonts/Gilroy-RegularItalic.woff') format('woff'),
//         url('../fonts/Gilroy-RegularItalic.ttf') format('truetype');
//     font-weight: normal;
//     font-style: italic;
// }

// @font-face {
//     font-family: 'Gilroy';
//     src: url('../fonts/Gilroy-SemiboldItalic.eot');
//     src: local('Gilroy Semibold Italic'), local('Gilroy-SemiboldItalic'),
//         url('../fonts/Gilroy-SemiboldItalic.eot?#iefix') format('embedded-opentype'),
//         url('../fonts/Gilroy-SemiboldItalic.woff2') format('woff2'),
//         url('../fonts/Gilroy-SemiboldItalic.woff') format('woff'),
//         url('../fonts/Gilroy-SemiboldItalic.ttf') format('truetype');
//     font-weight: 600;
//     font-style: italic;
// }

// @font-face {
//     font-family: 'Gilroy';
//     src: url('../fonts/Gilroy-HeavyItalic.eot');
//     src: local('Gilroy Heavy Italic'), local('Gilroy-HeavyItalic'),
//         url('../fonts/Gilroy-HeavyItalic.eot?#iefix') format('embedded-opentype'),
//         url('../fonts/Gilroy-HeavyItalic.woff2') format('woff2'),
//         url('../fonts/Gilroy-HeavyItalic.woff') format('woff'),
//         url('../fonts/Gilroy-HeavyItalic.ttf') format('truetype');
//     font-weight: 900;
//     font-style: italic;
// }

// @font-face {
//     font-family: 'Gilroy';
//     src: url('../fonts/Gilroy-Extrabold.eot');
//     src: local('Gilroy Extrabold'), local('Gilroy-Extrabold'),
//         url('../fonts/Gilroy-Extrabold.eot?#iefix') format('embedded-opentype'),
//         url('../fonts/Gilroy-Extrabold.woff2') format('woff2'),
//         url('../fonts/Gilroy-Extrabold.woff') format('woff'),
//         url('../fonts/Gilroy-Extrabold.ttf') format('truetype');
//     font-weight: 800;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'Gilroy';
//     src: url('../fonts/Gilroy-BoldItalic.eot');
//     src: local('Gilroy Bold Italic'), local('Gilroy-BoldItalic'),
//         url('../fonts/Gilroy-BoldItalic.eot?#iefix') format('embedded-opentype'),
//         url('../fonts/Gilroy-BoldItalic.woff2') format('woff2'),
//         url('../fonts/Gilroy-BoldItalic.woff') format('woff'),
//         url('../fonts/Gilroy-BoldItalic.ttf') format('truetype');
//     font-weight: bold;
//     font-style: italic;
// }

// @font-face {
//     font-family: 'Gilroy';
//     src: url('../fonts/Gilroy-UltraLightItalic.eot');
//     src: local('Gilroy UltraLight Italic'), local('Gilroy-UltraLightItalic'),
//         url('../fonts/Gilroy-UltraLightItalic.eot?#iefix') format('embedded-opentype'),
//         url('../fonts/Gilroy-UltraLightItalic.woff2') format('woff2'),
//         url('../fonts/Gilroy-UltraLightItalic.woff') format('woff'),
//         url('../fonts/Gilroy-UltraLightItalic.ttf') format('truetype');
//     font-weight: 200;
//     font-style: italic;
// }

// @font-face {
//     font-family: 'Gilroy';
//     src: url('../fonts/Gilroy-LightItalic.eot');
//     src: local('Gilroy Light Italic'), local('Gilroy-LightItalic'),
//         url('../fonts/Gilroy-LightItalic.eot?#iefix') format('embedded-opentype'),
//         url('../fonts/Gilroy-LightItalic.woff2') format('woff2'),
//         url('../fonts/Gilroy-LightItalic.woff') format('woff'),
//         url('../fonts/Gilroy-LightItalic.ttf') format('truetype');
//     font-weight: 300;
//     font-style: italic;
// }

// @font-face {
//     font-family: 'Gilroy';
//     src: url('../fonts/Gilroy-Heavy.eot');
//     src: local('Gilroy Heavy'), local('Gilroy-Heavy'),
//         url('../fonts/Gilroy-Heavy.eot?#iefix') format('embedded-opentype'),
//         url('../fonts/Gilroy-Heavy.woff2') format('woff2'),
//         url('../fonts/Gilroy-Heavy.woff') format('woff'),
//         url('../fonts/Gilroy-Heavy.ttf') format('truetype');
//     font-weight: 900;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'Gilroy';
//     src: url('../fonts/Gilroy-Thin.eot');
//     src: local('Gilroy Thin'), local('Gilroy-Thin'),
//         url('../fonts/Gilroy-Thin.eot?#iefix') format('embedded-opentype'),
//         url('../fonts/Gilroy-Thin.woff2') format('woff2'),
//         url('../fonts/Gilroy-Thin.woff') format('woff'),
//         url('../fonts/Gilroy-Thin.ttf') format('truetype');
//     font-weight: 100;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'Gilroy';
//     src: url('../fonts/Gilroy-ThinItalic.eot');
//     src: local('Gilroy Thin Italic'), local('Gilroy-ThinItalic'),
//         url('../fonts/Gilroy-ThinItalic.eot?#iefix') format('embedded-opentype'),
//         url('../fonts/Gilroy-ThinItalic.woff2') format('woff2'),
//         url('../fonts/Gilroy-ThinItalic.woff') format('woff'),
//         url('../fonts/Gilroy-ThinItalic.ttf') format('truetype');
//     font-weight: 100;
//     font-style: italic;
// }

