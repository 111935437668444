.b-firmware {
  margin-top: 110px;
  margin-bottom: 54px;
  padding: 161px 0 80px;
  background: url("../../assets/img/content/b-firmware/b-firmware.jpg") 50% 50% no-repeat;
  background-size: cover;
  text-align: center;

  &__group {
    display: flex;
    justify-content: center;
    padding-left: 70px;
    padding-bottom: 80px;
  }

  &__separator {
    display: flex;
    justify-content: center;
    align-items: baseline;
    width: 108px;
    height: 108px;
    margin: -10px 80px 0 110px;
    font-size: 40px;
    font-weight: 700;    
    color: #000;
    border-radius: 50%;
    text-align: center;
    line-height: 108px;
    background-color: #FFDB0A;
  }

  &__name {
    color: #FFF;
    font-size: 50px;
    font-weight: 700;
    line-height: 1.18;
    text-transform: uppercase;
  }

  &__link {
    display: inline-block;
    color: #FFDB0A;
    font-size: 25px;
    text-decoration: underline;

    &:hover {
      color: #FFF;
    }
  }


  &__info {
    margin-bottom: 110px;
  
    p {
      margin-bottom: 22px;
      font-size: 20px;
      line-height: 1.35;
    }
  }

  &_full {
    margin-top: 0;
    margin-bottom: 40px;
    padding-bottom: 116px;
  }



  @include media-breakpoint-down(xl) {
    padding: 80px 0 0;
    background-image: url("../../assets/img/content/b-firmware/b-firmware_lg.jpg");
    background-position:  50% 100%;

    &__info {
      margin-bottom: 80px;
    }
    &__group {
      padding-left: 40px;
      padding-bottom: 55px;
    }
    &__name {
      font-size: 36px;
    }
    &__separator {
      font-size: 28px;
      width: 78px;
      height: 78px;
      line-height: 78px;
      background-color: #FFDB0A;
      margin: -4px 53px 0 76px;
    }
    &__link {
      background-color: #000;
      display: block;
      padding-bottom: 15px;
      padding-top: 13px;
    }

    &_full {
      padding-top: 90px;
      padding-bottom: 30px;
    }
  }


  @include media-breakpoint-down(md) {
    padding: 47px 0 0;
    background-image: url("../../assets/img/content/b-firmware/b-firmware_sm.jpg");
    background-position:  50% 50%;
    background-size: 100% 100%;

    &__group {
      display: block;
      padding-bottom: 39px;
      padding-left: 0;
    }
    &__separator {
      margin: 60px auto;
      font-size: 26px;
      width: 68px;
      height: 68px;
      line-height: 68px;
    }
    &__name {
      font-size: 32px;
    }
    &__link {
      padding-left: 80px;
      padding-right: 80px;
    }    
    &__info {
      margin-bottom: 54px;
    }

    &_full {
      padding-bottom: 20px;
      background-image: url("../../assets/img/content/b-firmware/b-firmware_full-sm.jpg");
      background-position:  50% 0;
    }
  }

  @include media-breakpoint-down(sm) {
    padding-top: 57px;
    background-image: url("../../assets/img/content/b-firmware/b-firmware_xs.jpg");

    &__separator {
      margin-top: 50px;
      margin-bottom: 80px;
    }

    &__group {
      padding-bottom: 57px;
    }

    &__link {
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 19px;
      line-height: 1.16;
    }
    &_full {
      background-image: url("../../assets/img/content/b-firmware/b-firmware_full-xs.jpg");
    }
  }
}

.dark-block .b-firmware {
  margin-top: 0;
}



.b-firmware-stages {
  padding-bottom: 50px;

  &__number {
    display: block;
    width: 76px;
    height: 76px;
    margin: 0 auto 15px;
    border-radius: 50%;
    border: 1px solid #000;
    text-align: center;
    font-size: 40px;
    font-weight: 700;
    line-height: 74px;
  }
  &__title {
    margin-bottom: 29px;
    font-size: 40px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
  }
  &__row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -20px;
    margin-left: -20px;
    margin-bottom: 67px;
  }
  &__group {
    position: relative;
    flex: 0 0 50%;
    max-width: 50%;
    width: 100%;
    padding-right: 20px;
    padding-left: 20px;
  }
  &__name {
    position: relative;
    min-height: 64px;
    margin-bottom: 8px;
    padding-left: 60px;
    font-weight: 700;
    font-size: 23px;
    line-height: 1.3;
    &:before {
      position: absolute;
      left: 0;
      top: 6px;
      width: 47px;
      height: 47px;
      content: '';
    }
  }
  &__name_pos:before {
    background: url("../../assets/img/content/ic/check.png") 50% 50% no-repeat;
  }
  &__name_neg:before {
    background: url("../../assets/img/content/ic/del.png") 50% 50% no-repeat;
  }
  &__info {
    font-size: 20px;
    line-height: 1.35;
  }

  @include media-breakpoint-down(lg) {
    &__name {
      padding-left: 0;
    
      &:before {
        content: none;
      }
    }
  }

  @include media-breakpoint-down(md) {
    padding-bottom: 20px;
  
    &__row {
      display: block;
      margin-bottom: 46px;
    }
    &__group {
      max-width: 100%;
      margin-bottom: 24px;
    }
    &__title {
      font-size: 24px;
      margin-bottom: 23px;
    }
    &__number {
      margin-bottom: 21px;
    }
    &__name {
      margin-bottom: 15px;
    }
    &__name .d-block {
      display: inline;
    }
  }
}


.text-green {
  color: #08A700;
}
.text-red {
  color: #D80303;
}


.b-firmware-2 {
  padding-top: 7px;
  color: #FFF;
  background: url("../../assets/img/content/b-firmware/b-firmware_bg-2.jpg") 50% 50% no-repeat;
  padding-bottom: 87px;

  &__inner {
    max-width: 730px;
  }

  .title {
    text-align: left;
    margin-bottom: 30px;
  }
  p {
    color: #FFF;
    font-size: 20px;
    line-height: 1.35;
  }

  @include media-breakpoint-down(md) {
    padding-top: 11px;
    padding-bottom: 368px;
    background: #171717 url("../../assets/img/content/b-firmware/b-firmware-2__figure.png") 50% 100% no-repeat;
  }
}


.b-firmware-3 {
  padding-bottom: 56px;

  &__row {
    display: flex;
    align-items: flex-end;
  }
  &__inner {
    flex: 0 0 670px;
    max-width: 670px;
  }
  &__figure {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
    padding-left: 8%;
  }
  &__number {
    padding: 12px 0 5px;
    font-size: 103px;
    font-weight: 700;
    display: block;
  }
  &_percent {
    font-size: 59px;
    font-weight: 700;
    display: inline-block;
    vertical-align: 16%;
  }

  .title {
    text-align: left;
    margin-bottom: 30px;
    padding-top: 70px;
  }
  p {
    font-size: 20px;
    line-height: 1.35;
  }

  @include media-breakpoint-down(lg) {
    &__row {
      display: block;
    }
    &__inner {
      flex: 0 0 auto;
      max-width: 100%;
    }
    &__figure {
      text-align: center;
      padding-left: 0;
      margin-top: 50px;
    }
  }

  @include media-breakpoint-down(md) {
    padding-bottom: 47px;

    &__figure {
      display: none;
    }

    .title {
      padding-top: 50px;
    }
  }
}


.b-firmware-4 {
  min-height: 842px;
  padding-top: 22px;
  padding-bottom: 50px;
  color: #FFF;
  text-align: center;
  background: url("../../assets/img/content/b-firmware/b-firmware_bg-3.jpg") 50% 50% no-repeat;

  &__inner {
    padding-left: 73px;
  }
  &__figure {
    margin-top: 53px;
    position: relative;
    left: -25px;
  }
  p {
    color: #FFF;
    font-size: 20px;
    line-height: 1.35;
    text-shadow: 0px 0px 27px rgba(0, 0, 0, 0.004);
  }
  .title {
    margin-bottom: 30px;
    text-shadow: 0px 0px 27px rgba(0, 0, 0, 0.004);
  }


  @include media-breakpoint-down(xl) {
    padding-top: 0;

    &__figure {
      left: auto;
    }
    &__inner {
      padding-left: 0;
    }
  }

  @include media-breakpoint-down(lg) {
    .title {
      padding-top: 60px;
    }
  }

  @include media-breakpoint-down(md) {
    &__inner .d-block {
      display: inline;
    }
    &__figure {
      margin-top: 77px;
    }

    .title {
      padding-top: 32px;
      padding-bottom: 12px;
    }
    p {
      margin: 0 -5px;
    }
  }
}


.b-firmware-5 {
  padding-bottom: 46px;
  overflow: hidden;

  &__inner {
    max-width: 720px;
    padding-bottom: 100px;
    position: relative;
  
    &:after {
      content: '';
      position: absolute;
      right: -488px;
      top: 70px;
      background: url("../../assets/img/content/b-firmware/b-firmware_bg-4.jpg") no-repeat;
      width: 649px;
      height: 527px;
      z-index: -1;
    }
  }

  .title {
    padding-top: 73px;
    margin-bottom: 23px;
    text-align: left;
  }
  .btn {
    margin: 0 auto;
    font-weight: 700;
    position: relative;
    left: 30px;
  }
  p {
    margin-bottom: 22px;
    font-size: 20px;
    line-height: 1.35;
  }

  @include media-breakpoint-down(xl) {
    .btn {
      left: auto;
    }
  }

  @include media-breakpoint-down(lg) {
    &__inner:after {
      position: relative;
      display: block;
      right: auto;
      top: auto;
    }
  }

  @include media-breakpoint-down(md) {
    padding-bottom: 0;
  
    &__inner:after {
      width: 100%;
      height: 230px;
      background-size: contain;
      background-position: center;
      margin: -27px 0 -10px;
    }

    .title {
      padding-top: 52px;
    }
  }
}


@include media-breakpoint-down(md) {
  .b-firmware-2 .title,
  .b-firmware-3 .title,
  .b-firmware-4 .title,
  .b-firmware-5 .title {
    margin-bottom: 12px;
    font-size: 24px;
    line-height: 1.375;
  }
}


.firmware-tooltipe {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  font-size: 15px;
  line-height: 15px;
  color: #bd1010;
  border-radius: 10px;
  border: 1px solid #b90000;
  background-color: transparent;
  cursor: pointer;
}


.firmware-block {
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;

  @include media-breakpoint-down(md) {
    justify-content: center;
  }

  @include media-breakpoint-down(sm) {
    margin-bottom: 0;
  }
}
.firmware-price {
  margin-right: 10px;
  padding: 6px 10px;
  font-size: 20px;
  background-color: #f3f3f3;

  @include media-breakpoint-down(sm) {
    padding: 4px 6px;
    font-size: 17px;
  }
}

.firmware-description {
  margin-right: 15px;
  font-size: 14px;
  line-height: 15px;

  @include media-breakpoint-down(sm) {
    font-size: 12px;
  }
}