.b-advant {
  display: flex;
  justify-content: space-between;
  padding-top: 60px;
  &__item {
    display: flex;
    margin-bottom: 40px;
    padding-right: 24px;
  }
  &__ic {
    flex: 0 0 auto;
    width: 65px;
    margin-right: 28px;
    text-align: center;
  }
  &__inner {
    width: 370px;
  }
  &__title {
    margin-top: 0; 
    margin-bottom: 10px;
    font-size: 28px;
    font-weight: 700;
  }

  @include media-breakpoint-down(xl) {
    padding-bottom: 38px;
    &__item {
      padding-right: 20px;
    }
    &__ic {
      margin-right: 14px;
    }
    &__inner {
      width: 262px;
    }
    &__title {
      font-size: 18px;
    }
    &__info {
      font-size: 16px;
    }
  }

  @include media-breakpoint-down(md) {
    padding-top: 72px;
    flex-direction: column;

    &__item {
      margin-bottom: 20px;
      padding-right: 0;
      &:nth-child(2) {
        .ic {
          width: 42.5px;
        }
      }
      &:nth-child(3) {
        .ic {
          width: 41px;
        }
      }
    }
    &__inner {
      width: 232px;
    }
    &__img {
      margin-bottom: 33px;
    }
    &__ic {
      margin-top: 7px;
      margin-left: -3px;
      text-align: left;
    }
  }
}