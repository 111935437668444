.overlay {
  &.active {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: block;
  }
}

.gtl-data-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 90%;
  max-width: 840px;
  max-height: 540px;
  padding: 80px 80px 80px 160px;
  background: #F0F0F0;
  box-shadow: 0 0 16px #a2a2a2;
  transform: translate(-50%, -50%) scale(0);
  transition: all .3s;
  z-index: 100;
  line-height: 1;
  overflow-y: auto;

  &.active {
    transform: translate(-50%, -50%) scale(1);
  }

  .close_btn {
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 26px;
    font-weight: 700;
    color: #000;
    cursor: pointer;
  }

  .gtl-data__title {
    font-size: 25px;
    font-weight: 700;
    text-transform: uppercase;
  }

  @include media-breakpoint-down(lg) {
    padding: 40px 30px 30px;
  }
}

.character-gtl {
  .tab-list {
    li {
      position: relative;
      padding-left: 28px;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 2px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #000;
      }
    }

    .tab-list__title {
      padding-left: 0;

      &::before {
        display: none;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    .tab-list li {
      padding-left: 17px;
      &:before {
        width: 7px;
        height: 7px;
      }
    }
  }
}

.gtl-list {
  display: none;
  width: 100%;
  max-width: 520px;

  &.active {
    display: block;
  }
}
