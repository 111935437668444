.install-steps {
  text-align: center;
  margin-top: 46px;
  vertical-align: top;
  min-height: 90px;

  &__step {
    display: inline-block;
    vertical-align: top;

    &.mod-step-2 {
      .install-steps__num {
        margin-left: 88px;
      }

      .install-steps__title {
        margin-right: 37px;
      }

      .install-steps__pics {
        text-align: left;
        padding-left: 88px;
      }
    }
  }

  &__num {
    vertical-align: top;
    object-fit: contain;
    font-size: 24px;
    font-weight: 700;
    line-height: 22px;
    text-align: right;
    color: #5e5e5e;
    display: inline-block;
    margin-right: 22px;
  }

  &__title {
    vertical-align: top;
    font-size: 9px;
    line-height: 1.19;
    letter-spacing: 0.9px;
    text-align: left;
    color: #000;
    display: inline-block;
    text-transform: uppercase;
  }

  &__pics {
    margin-top: 15px;
    position: relative;
  }

  &__img {
    position: absolute;

    &.mod-st-1-dec {
      top: 15px;
      left: 0px;
    }

    &.mod-st-1-car {
      top: 4px;
      left: 33px;
    }

    &.mod-st-2-dec {
      top: 0px;
      left: 88px;
    }

    &.mod-st-2-car {
      top: 5px;
      left: 116px;
    }

    &.mod-st-3-dec {
      top: 10px;
      left: 0px;
    }

    &.mod-st-3-car {
      top: 7px;
      left: 30px;
    }
  }

  @include media-breakpoint-down(lg) {
    &__title {
      font-size: 12px
    }
    &__pics {
      min-height: 70px
    }
  }

  @include media-breakpoint-down(md) {  
    &__img.mod-st-2-dec {
      left: 0px
    }
    
    &__img.mod-st-2-car {
      left: 28px
    }

    &__step {
      display: block;
      margin-left: 25%;
      text-align: left;

      &.mod-step-2 {
        .install-steps__num {
          margin-left: 0px
        }

        .install-steps__title {
          margin-right: 0px
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    &__step {
      margin-left: 15%
    }
  }
}



.install-switch {
  display: flex;
  margin-top: 30px;
  
  &__btn {
   width: 33.3333%;
   color: #fff;
   font-size: 15px;
   text-transform: uppercase;
   padding: 2em .5em;
   background-color: #a1a1a1;
   border: none;
   border-right: 1px solid rgb(94, 94, 94);
   outline: none !important;
   transition: all .2s;

    &:hover,
    &.active {
      background-color: var(--bs-green);
      border-color: rgb(255, 221, 0);
      color: #fff;
    }
  }

  @include media-breakpoint-down(md) {  
    margin-top: 0px;
    
    &__btn {
      padding: 15px 10px;
    }
  }

  @include media-breakpoint-down(sm) {
    flex-direction: column;

    &__btn {
      width: 100%;
      border: none;
      margin-bottom: 1px;
    }
  }
}


.install-video {
  text-align: center;
  margin-top: 42px
}

.install-video .install-video-iframe {
  width: 659px;
  height: 369px
}


.install-gallery {
  counter-reset: galleryMain;
  &__item {
    position: relative;

    &:after {
      content: counter(galleryMain);
      counter-increment: galleryMain;
      position: absolute;
      font-size: 55.5px;
      line-height: 1.56;
      letter-spacing: 1.4px;
      color: #ffdb0a;
      top: 3%;
      left: 3%;
    }
  }

  @include media-breakpoint-down(md) {
    &__item {
      margin-bottom: 35px;

      &:after {
        font-size: 42.5px;
        top: -3%;
        left: 5%;
      }
    }
  }
}

.install-gallery-title {
  font-size: 25.6px;
  letter-spacing: 2px;
  text-align: center;
  color: #ffdd00;

  @include media-breakpoint-down(md) {
    font-size: 17px
  }
}


.install-gallery-current {
  margin-top: 35px;

  .slick-arrow {
    position: absolute;
    top: 196px;
    width: 22px;
    height: 43px;
    background: url(../../assets/img/content/b-install/arrow.png);
    z-index: 5;
  }
  .slick-prev {
    left: calc(3%);
    transform: rotate(180deg)
  }
  .slick-next {
    right: calc(3%);
    transform: none
  }


  @include media-breakpoint-down(xl) {
    .slick-arrow {
      top: 180px;
    }
  }
  @include media-breakpoint-down(lg) {
    .slick-arrow {
      top: 134px;
    }
  }
}



.install-gallery-description {
  margin: 30px auto 0;
  text-align: center;
  font-size: 20px;
  line-height: 1.3;
  letter-spacing: 0.4px;

  @include media-breakpoint-down(lg) {
    font-size: 16px;
  }

  @include media-breakpoint-down(md) {
    margin-top: 24px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: left;
    font-size: 18px;
  }
}

.install-gallery-preview {
  margin-top: 30px;
  counter-reset: gallery;

  &__item {
    position: relative;
    width: calc(100% / 8);
    margin: 0 0 25px;
    padding: 0 4px;
    cursor: pointer;
    &:after {
      content: counter(gallery);
      counter-increment: gallery;
      position: absolute;
      font-size: 15px;
      font-weight: 700;
      color: #ffdb0a;
      letter-spacing: 0.4px;
      top: 6px;
      left: 12px;
      cursor: pointer;
    }

    &:hover,
    &.slick-active {
      .img {
        border-color: #ffdb0a;
      }
    }
  }

  .img {
    width: 100%;
    border: solid 2px #707070;
    transition: all .3s;
  }

  .slick-track {
    width: 100% !important;
    transform: none !important; 
  }
}

.install-tab {
  max-width: 1033px;
  margin-left: auto;
  margin-right: auto;
}


