.container-checkout {
  width: 610px;
  margin: 0 auto;
  padding: 0 20px;

  & .container-checkout {
    padding: 0;
  }


  @include media-breakpoint-down(md) {
    width: 100%;
  }
}

.cart-title {
  width: 100%;
  padding: 25px 0 20px;
  font-size: 25px;
}

.kr_checkout {
  background-color: #f7f7f7;
  display: block !important;

  &.ordering {
    position: relative;

    &::after {
      content: '';
      width: 100%;
      height: 55px;
      background-color: #f7f7f7;
      position: absolute;
      left: 0;
      bottom: -52px;
    }


    .title {
      padding: 70px 0 15px;
      margin-bottom: 0;
    }
  }
}


.numInMinus,
.numInPlus {
  cursor: pointer;
}

.promo-code-error {
  color: red;
}

.e1 {

  .p2 {
    padding-top: 5px;
    font-size: 11px;
    line-height: 1.2;
    text-transform: uppercase;
    color: #000;
  }
}

.calc {
  &.kr_cart_row_price:not(.kr_summa) {
    font-weight: 700;
  }

  &.kr_summa,
  &.kr_cart_discounts {
    font-size: 14px;
  }

  &.kr_cart_discounts span:first-child::before {
    content: '— ';
  }
}

.ordering .calc.kr_summa, 
.ordering .calc.kr_cart_discounts {
    font-size: 14px;
}

.total-box {
  width: 100%;
  justify-content: center;
  position: relative;
  left: -83px;
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 4px;
  }

  .kr_cart_row_price.flex,
  .kr_cart_nds.flex {
    justify-content: center;
  }

  .p2 {
    text-align: right;
    flex: 0 0 220px;
    max-width: 220px;
  }

  &.summa {
    margin: 15px 0 33px;
    align-items: baseline;

    .p2,
    .s2 {
      font-weight: 700;
    }

    .s2 {
      font-size: 24px;
    }
  }
}

.kr_cart_row {
  align-items: flex-start;
  padding: 20px 0;
  border-top: 1px solid #eaeaea;
  border-bottom: 1px solid #eaeaea;
  display: flex;
  justify-content: space-between;

  &.kr_cart_row--column {
    flex-direction: column;
  }

  .wrp {
    width: 100%;
  }

  .e1 {
    max-width: 220px;
    flex: 0 0 220px;
    padding-right: 10px;

    &::before {
      display: none;
    }

    .p1 {
      font-size: 24px;
      text-transform: uppercase;
      font-weight: 700;
      margin-bottom: 0;
    }
  }

  .e2 {
    text-align: right;
    max-width: 165px;
    flex: 0 0 165px;
  }

  .e3 {
    flex-grow: 1;

    .numberInput {
      justify-content: center;
      display: flex;
      align-items: center;
      font-size: 24px;
    }

    .kr_cart_row_quantity_text {
      padding: 0 15px;
      font-size: 18px;
      font-weight: 700;
    }

    .closeGTGA.kr_cart_row_remove {
      display: block;
      width: 30px;
      height: 30px;
      background: url('../../assets/img/content/ic/close-black.png') center center no-repeat;
    }
  }
}

.kr_summa {
  width: auto;
  flex: 0 0 165px;
  max-width: 165px;
}

.kupitGAGT__action__title {
  display: block;
  font-weight: 700;
  font-size: 17px;
}


.kupitGAGT_payType__radio_wrapper {
  display: flex;
  align-items: center;
  bottom: 0;
  flex-wrap: wrap;
}

.kupitGAGT_payType {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: -9px;

  li {
    background-color: #fbfbfb;
    border: 1px solid #dedede;
    height: 65px;

    &:not(:last-child):not(.active) {
      border-bottom-color: transparent;
    }

    &.active {
      background-color: #fff;
      border-color: #5e5e5e;

      + li {
        border-top-color: transparent;
      }

      .kupitGAGT_payType__radio {
        &::after {
          content: '';
          display: block;
          width: 50%;
          height: 50%;
          background: #ffdb0a;
          border-radius: 50%;
        }
      }
    }

  }
}

.kupitGAGT_payType__radio {
  flex: none;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  margin-right: 21px;
  background: #959595;
  border-radius: 50%;
}

.kupitGAGT_delivery_text { 
  padding-left: 15px;
}

.calc.kr_cart_row_price, 
.calc.kr_cart_nds, 
.calc.kr_cart_discounts {
  font-size: 24px;
}

.pay__text {
    display: inline-block;
    margin-right: 15px;
    white-space: nowrap;
    font-size: 17px;
    line-height: 1;
}
.pay__text_action, 
.pay__text_action_alt {
    display: block;
    color: #FC1010;
    margin-top: 3px;
}


.pay_wrapper {
  display: flex;
  justify-content: space-between;
  height: 100%;
  text-decoration: none;
  transition: opacity 0.2s;
  color: #020303;

  &:focus {
    outline: none !important;
    color: #020303;
  }

  &:hover {
    opacity: 0.8;
    text-decoration: none;
    color: #23527c;
  }

  & .pay_right.kupitGAGT__action {
    display: flex;
    height: 65px;
    margin-top: -1px;
    margin-right: -1px;
    background-color: #a2cd1e;

    .kupitGAGT__action-inner {
      padding-right: 28px;
      padding-left: 10px;
      font-size: 15px;
    }
  }

  .pay__text {
    display: inline-block;
    margin-right: 15px;
    white-space: nowrap;
    font-size: 17px;
    line-height: 1;

    &.pay__text_action,
    &.pay__text_action_alt {
      display: block;
      margin-top: 3px;
      color: #fc1010;
    }
  }

  .pay__img {
    margin-left: -9px;
    margin-top: -20px;
  }

  .s2 {
    font-size: 14px;
    text-transform: uppercase;
    padding-top: 10px;
    text-align: center;
    text-decoration: none;
  }

  .pay_right {
    display: flex;
    justify-content: center;
    align-items: baseline;
    flex-direction: column;
    align-items: center;
  }
}


.pay_left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 20px;
}

.promo-code {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 60px;
  overflow: hidden;

  a {
    color: #959595;
    font-size: 20px;
    border-bottom: 1px dashed #959595;
    text-decoration: none !important;
    line-height: 1.2;
    transition: all 0.3s;

    &[aria-expanded="true"] {
      display: none;
    }

    &:hover {
      border-color: transparent;
    }
  }

  &__dropdown {
    width: 100%;
    a {
      opacity: 0;
      height: 0;
      overflow: hidden;
    }

    .d-flex {
      width: 100%;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      .input {
        width: 250px;
        margin-right: 25px;
        margin-bottom: 20px;

        &::-webkit-input-placeholder {
          text-transform: initial;
          font-size: 14px;
        }

        &::-moz-placeholder {
          text-transform: initial;
          font-size: 14px;
        }

        &:-moz-placeholder {
          text-transform: initial;
          font-size: 14px;
        }

        &:-ms-input-placeholder {
          text-transform: initial;
          font-size: 14px;
        }
      }
    }
  }
}

.order_rules {
  text-align: center;
  margin-top: 30px;
  line-height: 23px;
  font-size: 14px;

  a {
    color: #000;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  }

  .hide_rules {
    width: 100%;
    height: 40px;
    background: url('../../assets/img/content/ic/close-black.png') 95% center no-repeat;
  }
}

div#show_rules,
div#show_rules2 {
  position: fixed;
  z-index: 999;
  background-color: white;
  color: black;
  text-align: center;
  top: 50%;
  left: 50%;
  width: 95%;
  height: 500px;
  width: 983px;
  text-align: left;
  padding: 15px;
  transform: translate(-50%, -50%);
  overflow: auto;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);

  .title {
    padding-top: 0;
    font-size: 35px;
  }
}

.closeGTGA.kr_cart_row_remove {
  display: block;
  width: 30px;
  height: 30px;
  background: url('../../assets/img/content/ic/close-black.png') center center no-repeat;
}


.kr_cart {
  margin-top: 30px;

  .kr_cart_row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
    border-top: 1px solid #eaeaea;
    border-bottom: 1px solid #eaeaea;

    &.kr_cart_row--column {
      flex-direction: column;
    }

    .e1 {
      margin-bottom: 0;

      &::before {
        display: none;
      }

      .p1 {
        margin-bottom: 0;
        font-size: 24px;
        text-transform: uppercase;
        font-weight: 700;
      }
    }

    .e2,
    .e3,
    .e4 {
      .numberInput {
        justify-content: center;
      }

      .closeGTGA.kr_cart_row_remove {
        margin: 0 auto;
      }
    }
  }
  &_promo {
    width: 210px;
    height: 45px;
  }
  &_row p {
      margin-bottom: 0;
  }
}
.ordering .total-box {
    margin-bottom: 24px;
    left: 0;
    justify-content: flex-start;
}

.total-box .p2 {
    text-align: right;
    flex: 0 0 220px;
    max-width: 220px;
}
.ordering .kr_summa {
    width: auto;
    flex: 0 0 165px;
    max-width: 165px;
}
.kr_summa {
    text-align: right;
}
.kr_checkout_order {
  height: 40px;
  min-width: 210px;
}

@include media-breakpoint-down(md) {
  .kupitGAGT_delivery_text {
    display: block;
    width: 100%;
    padding-left: 37px;
    padding-top: 10px;
  }
  .kupitGAGT_position {
    padding-bottom: 25px;
  }

  .ordering .summa.total-box {
    margin-top: 19px;
    margin-bottom: 5px;
  }

  .ordering .total-box {
    margin-bottom: 19px;
    padding-top: 2px;

    &:last-child {
      margin-bottom: 18px;
    }

    .kr_cart_row_price.flex,
    .kr_cart_nds.flex {
      width: 100%;
      justify-content: space-between;
    }
  }

  .promo-code__dropdown .d-flex {
    flex-direction: column;
    align-items: center;

    .input {
      width: 210px;
      margin-right: 0;
      margin-bottom: 20px;
    }
  }

  .kupitGAGT__line.d-flex {
    flex-wrap: wrap;
  }

  .inps-wrapper--half {
    width: 100%;
  }

  .kupitGAGT_payType__radio_wrapper {
    flex-wrap: wrap;
  }

  .kupitGAGT_payType {
    margin-top: 0;

    li:last-child {
      margin-bottom: 0;
    }

    .kupitGAGT__action__title {
      font-size: 13px;
    }

    .kupitGAGT__action-inner {
      font-size: 13px;
      padding-right: 12px;
      padding-bottom: 5px;
    }
  }

  .promo-code {
    margin-top: 40px;
  }

  .tovari.kr_cart_row {
    flex-wrap: wrap;
    display: block;

    .e1,
    .e2,
    .e3,
    .e4 {
      width: 100%;
      max-width: 100%;
      padding-left: 0;
      padding-right: 0;
      margin-bottom: 20px;
      text-align: center;
    }
  }

  .total-box {
    left: 0;
    width: 100%;
    justify-content: space-between;
  }

  .ordering .kr_summa {
    flex: 0 0 165px;
    max-width: 165px;
  }

  .pay_wrapper_installment {
    width: 100%;
  }
  .pay_wrapper_installment .kupitGAGT_payType__radio_wrapper {
    position: relative;
    width: 100%;
  }
  .pay__text_mob-br {
    display: block;
  }
  .pay__text_installment {
    width: 170px;
  }
  .pay__text_installment .pay__text_action_alt {
    white-space: normal;
  }
  .pay_wrapper {
    display: block;
    height: auto;
  }
  .kupitGAGT_payType li {
    height: auto;
    min-height: 65px;
    align-items: center;
    display: flex;
    padding-top: 13px;
    padding-bottom: 13px;
  }
  .pay_wrapper .pay_right.kupitGAGT__action {
    height: auto;
    margin-top: 8px;
    margin-left: 44px;
    padding: 5px 0 0 2px;
  }
  .pay_wrapper .pay_left img {
    display: none;
  }
  .pay_wrapper .pay_left .pay__text_img {
    display: block;
    position: absolute;
    top: 5px;
    right: 10px;
  }
  .kupitGAGT__action__title {
    display: inline-block;
    margin-right: 3px;
  }
  .pay__text {
    margin-right: 0;
  }
  .kupitGAGT_payType__radio {
    margin-right: 10px;
  }
  .pay_left {
    padding-left: 10px;
  }
  .kr_cart_promo_val {
    width: 210px;
    margin-right: 0;
    margin-bottom: 20px;
  }
  .tovari.kr_cart_row {
    flex-wrap: wrap;
    display: block;
  }
}


@include media-breakpoint-down(sm) {
  .total-box .p2 {
    flex: 0 0 155px;
    max-width: 155px;
  }
}
