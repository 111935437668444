.select-block {
  @include media-breakpoint-down(md) {
    max-width: 345px;
    margin: 0 auto; 

    .title {
      font-size: 23px;
      margin-bottom: 25px; 
    }

   &.gan-column .h2 {
      padding-top: 45px;
      margin-top: 0; 
    }
    .title {
      padding-top: 20px;
    }
  }

  @include media-breakpoint-down(sm) {
    &.gan-column .h2 {
      margin-top: 0;
      padding-top: 35px;
    }

    .title {
      padding-top: 0; 
    }
  }
}