.b-article {
  &__title {
    margin: 50px 0 24px;
    font-size: 24px;
    text-transform: uppercase;
  }

  &__content p,
  &__content li {
    font-size: 22px;
    line-height: 1.333;
  }
  &__content p {
    margin-bottom: 30px; 
  }
  &__content img,
  &__content iframe,
  &__content ul,
  &__content ol {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  &__content img {
    max-width: 100%;
    height: auto;
  }
  &__content ul,
  &__content ol {
    margin-left: 0;
  }
  &__content li {
    margin-bottom: 10px;
  }

  &__content ul li {
    padding-left: 38px;
    position: relative;
  }

  &__content ul li:before {
    content: '';
    position: absolute;
    left: 14px;
    top: 10px;
    background-color: #a2cd1d;
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }

  @include media-breakpoint-down(md) {
    &__content p,
    &__content li, {
      font-size: 20px;
    }
    &__content img,
    &__content iframe,
    &__content ul,
    &__content ol {
      margin-top: 25px;
      margin-bottom: 25px;
    }
    &__content p {
      margin-bottom: 25px;
    }
  }
} 



.b-articles {
  &__item {
    display: flex;
    margin-bottom: 50px;
  }
  &__img {
    flex: 0 0 250px;
    padding-right: 30px;
  }
  &__main {
    flex-grow: 1;
  }
  &__title {
    margin-top: 0;
    font-size: 30px;
  }
  &__info {
    font-size: 22px;
    line-height: 1.333;
  }

  @include media-breakpoint-down(md) {
    &__item {
      display: block;
    }
    &__img {
      margin-bottom: 20px;
      padding-right: 0;
    }
    &__info {
      font-size: 20px;
    }
  }
}
