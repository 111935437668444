.pg404 {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  background-color: var(--bs-black);

  img {
    display: block;
    max-width: 100%;
    margin: 0 auto;
  }

  .txtCont {
    .p1, .p2 {
      position: absolute;
      left: 0;
      right: 0;
      color: #fff;
      text-align: center;

    }
    .p1 {
      top: 10%;
      font-size: 100px;
    }
    .p2 {
      top: 15%;
      padding-top: 30px;
      font-size: 30px;
      line-height: 1.3;
  
      a {
        color: var(--bs-yellow);

        &:hover{
          text-decoration: none;
        }
      }
    }
  }


  @include media-breakpoint-down(md) {
    .txtCont {
        width: 100%;
        flex-direction: column;
    }
    .txtCont p {
        float: none;
    }
    .txtCont p.p1 {
        font-size: 62px;
        line-height: 30px;
        text-align: center;
    }
    .txtCont p.p2 {
        width: 170px;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        margin: 25px auto 0;
    }
  }
}

