.kr_chippicker_product {

  @include media-breakpoint-down(md) {

    .h2 {
      margin-top: 0;
      margin-bottom: 18px;
      font-size: 32px;

      span {
        margin: 11px -10px 0;
        font-size: 17px;
        line-height: 20px;
        letter-spacing: .04em;
      }
    }

    .tab {
      margin-bottom: 11px;
    }

    .current-price {
      display: block;
      font-size: 33px;
      font-weight: 700;
    }

    .product-price {
      text-align: center;
      padding-left: 0;
      font-size: 17px;
    }

    .buy-block__item {
      margin-top: 18px;
      padding-left: 3px;
      padding-right: 2px;
      text-align: left;
    }

    .wrap-price .economy-block {
      display: flex;
      justify-content: center;
      margin-top: 4px;
    }

    .buy-block {
      margin-top: 0;

      .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        width: 100%;
        min-height: 58px;
        margin-bottom: 4px;
        white-space: normal;
        font-size: 20px;
        padding: 0;
        .btn__text {
          margin-right: 6px;
          font-size: inherit;
        }
      }
    }

    .tab-list__ico {
      width: 45px;
    }

    .buy-block__text {
      text-align: left;
    }

    .gan__item {
      &:not(:first-child) {
        .h2 {
          padding-top: 48px;
        }
      }

      p {
        font-size: 17px;
      }

      .gan__img__data {
        margin: -22px auto 0;
        display: flex;
        justify-content: space-between;
        max-width: 267px;

        strong {
          font-size: 39px;
          font-weight: 700;
        }

        small {
          font-size: 19px;
        }

        span {
          padding: 0;
        }
      }

      .gan__img {
        margin-bottom: 7px;
      }

      .view {
        flex-direction: row;
        margin-bottom: 3px;
        justify-content: center;
      }
    }

    .gan-column {
      &--ga {
        .gan__item .gan__img__data {
          margin-top: 0;
        }
      }
    }

    .old-price {
      margin-bottom: 3px;
      font-size: 18px;
    }

    .tab-list {
      margin-bottom: 0;

      li:not(.gan__mob-hid) {
        margin-bottom: 16px;
      }

      &__text {
        font-size: 17px;
        line-height: 1.2;
        padding-right: 0;
      }
    }
  }
}



.kr-chippicker__wrapper {

  @include media-breakpoint-down(md) {
    .h1-title {
      font-size: 22px;
    }
    
    .title--top {
      margin-bottom: 21px;
    }
    
    .title span {
      font-size: 17px;
      padding-top: 18px;
    }
  }
}
