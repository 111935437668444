.right-fix {
  position: fixed;
  right: 0;
  top: 50%;
  display: flex;
  flex-direction: column;
  transform: translateY(-50%);
  z-index: 20;

  &__item {
    width: 104px;
    height: 125px;
    background-color: #c2c2c2;
    padding: 0 4px;
    &:not(&__item_discount):hover {
      opacity: .7;
    }
    &:last-child {
      border-bottom: none;
    }
    a {
      display: flex;
      height: 100%;
      justify-content: center;
      align-items: center;
      text-decoration: none;
    }
    img {
      display: block;
      max-width: 100%;
    }
  }
  &__item_discount {
    text-align: center;
    background-color: #FFDB0A;
    &:hover {
      background-color: rgba(255, 219, 10, .6);
    }
    a {
      flex-wrap: wrap;
    }
  }
  &__info {
    display: block;
    font-size: 13px;
    line-height: 150%;
    color: #020303;
  }
  &__price {
    display: block;
    margin-top: 3px;
    font-size: 22px;
    font-weight: 700;
    line-height: 150%;
    color: #020303;
    white-space: nowrap;
  }


  @include media-breakpoint-down(lg) {
    display: none;
  }
}


.discount-mob {
  position: fixed;
  left: 44px;
  bottom: 20px;
  display: none;
  height: 58px;
  padding: 0 14px 0 12px;
  align-items: center;
  color: #020303;
  text-decoration: none;
  background: #FFDB0A;
  border: none;
  z-index: 90;


  @include media-breakpoint-down(lg) {
    display: flex;
  }

  @include media-breakpoint-down(md) {
    left: 16px;
    bottom: 16px;
  }
}

.discount-mob:hover {
  text-decoration: none;
  color: #020303;
}
.discount-mob__info {
  font-size: 12px;
  line-height: 150%;
  text-align: center;
}
.discount-mob__price {
  padding-left: 23px;
  font-size: 18px;
  font-weight: 700;
  white-space: nowrap;
}

