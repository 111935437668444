.b-main {
  position: relative;
  box-shadow: 0 0 40px #ccc;
  height: calc(100vh - 80px);
  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #1D1D1D;
    opacity: 0.3;
  }

  &__title {
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 80px;
    font-weight: 700;
    line-height: 1.55;
    color: #fff;
    text-transform: uppercase;
  }
  &__info {
    font-size: 28px;
    font-weight: 700;
    line-height: 1.7;
    color: #fff;
  }
  &__inner {
    position: absolute;
    bottom: 60px;
    left: calc((100vw - 1272px) / 2);
    width: 1272px;
    z-index: 1;
  }
  &__block {
    display: flex;
    margin-top: 60px;
  }
  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (min-width:1400px) and (max-width:1479.98px) {
    &__inner {
      left: 104px;
      width: calc(100vw - 208px);
    }
  }

  @media (min-width:1200px) and (max-width:1399.98px) {
    &__inner {
      left: calc((100vw - 932px) / 2);
      width: 932px;
    }
  }

  @media (min-width:992px) and (max-width:1199.98px) {
    &__inner {
      left: calc((100vw - 836px) / 2 - 44px);
      width: 836px;
    }
  }

  @media (min-width: 768px) and (max-width: 991.98px) {
    &__inner {
      left: calc((100vw - 680px) / 2);
      width: 680px;
    }
  }

  @include media-breakpoint-down(xl) {
    box-shadow: none;
    &__title {
      margin-bottom: 0;
      font-size: 40px;
      line-height: 1;
    }
    &__info {
      font-size: 20px;
    }
    &__inner {
      top: 25px;
      bottom: 30px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    &__block {
      flex-wrap: wrap;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  @include media-breakpoint-down(md) {
    height: auto;
    &__title {
      margin-bottom: 6px;
      font-size: 38px;
      line-height: 36px;
    }
    &__inner {
      top: 14px;
      left: 16px;
      right: 16px;
      bottom: 20px;
      width: auto;
    }
    &__info {
      max-width: 240px;
      font-size: 18px;
      line-height: 20px;
    }
    &__block {
      margin-top: 0;
    }
    &__btn-mob {
      padding: 10px; 
      font-size: 16px;
      font-weight: 500;
      line-height: 19.48px;
      text-transform: none;
    }
  }
}


.b-main-action {
  position: relative;
  display: flex;
  align-items: flex-end;
  width: 240px;
  padding: 20px;
  background: #FFDB0A;
  color: #020303;

  .fig-1 {
    position: absolute;
    top: 8px;
    left: 20px;
    width: 34px;
    height: 34px;
  }
  .fig-2 {
    position: absolute;
    bottom: 14px;
    right: 14px;
    width: 107px;
    height: 107px;
  }

  @include media-breakpoint-down(xl) {
    min-height: 120px;
    width: 213px;
    margin-bottom: 20px;
    padding-right: 30px;
    font-size: 16px;

    .fig-1 {
      width: 26px;
      height: 26px;
    }
    .fig-2 {
      width: 81px;
      height: 81px;
    }
  }

  @include media-breakpoint-down(md) {
    min-height: 0;
    width: 200px;
    margin-bottom: 0;
    padding: 10px;
    font-weight: 700;

    .fig-1,
    .fig-2 {
      display: none;
    }
  }
}


.b-main-form {
  margin-right: 20px;
  padding: 20px;
  background: #F5F5F599;

  &.st-one & {
    &__group {
      width: 400px;
    }
    &__select {
      width: 100%;
    }
  }

  &.st-three & {
    &__select {
      width: 200px;
      &:nth-child(2) {
        left: 220px;
        &.active {
          left: 0;
        }
      }
    }
    &__group {
      width: 640px;
      margin-right: 20px;
    }
  }

  &__top {
    display: flex;
    justify-content: space-between;
  }
  &__group {
    position: relative;
    width: 650px;
    margin-right: 10px;
  }
  &__select {
    position: absolute;
    top: 0;
    width: 320px;
    transition: all .3s;
    &:first-child {
      left: 0;
    }
    &:last-child {
      right: 0;
    }
    &.active {
      width: 100% !important;
      z-index: 1;
    }
  }
  &__link {
    padding: 0;
    border: none;
    background-color: transparent;
    font-size: 18px;
    line-height: 1.17;
    color: #717171;
    text-decoration: underline;
    transition: all .3s;
    &:hover {
      color: #000;
      text-decoration: none;
    }
  }
  &__row {
    display: flex;
    margin-top: 10px;
  }
  &__btn {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 57px;
    height: 60px;
    background-color: var(--bs-secondary);
    border: none;
    stroke: #fff;
    stroke-width: 2px;

    &:hover {
      background-color: #149900;
    }
  }

  @include media-breakpoint-down(xl) {
    order: 2;
    font-size: 16px;

    &__link {
      font-size: 16px;
    }
  }

  @include media-breakpoint-down(lg) {
    &__select {
      width: 240px;
    }

    &__group {
      width: 500px;
      margin-right: 20px;
    }
    

    &.st-three & {
      &__select {
        width: 160px;
        &:nth-child(2) {
          left: 173px;
        }
      }
      &__group {
        width: 506px;
        margin-right: 13px;
      }
    }
  }

  @include media-breakpoint-down(md) {
    display: none;
  }
}