.instruction-block {
  width: 100%;
  min-height: 275px;  
  margin: 100px 0;
  padding-top: 50px;
  padding-bottom: 30px;
  background: url('../../assets/img/content/b-instruction/left-car.png') no-repeat 0 -60px, 
              url('../../assets/img/content/b-instruction/right-ford.png') no-repeat 100% 0, 
              #000;
  text-align: center;

  p {
    display: inline-block;
    max-width: 930px;
    width: 100%;
    margin-bottom: 45px;
    font-size: 25px;
    line-height: 33px;
    color: #ffffff;
    text-align: center;
  }

  @include media-breakpoint-down(xl) {
    min-height: auto;
    padding-bottom: 50px;
    background: #000;
  }

  @include media-breakpoint-down(md) {
    padding-top: 40px;
    padding-bottom: 40px;
    margin: 70px 0;

    p {
      font-size: 18px;
      margin-bottom: 28px;
    }
  }
}

.instruction__link {
  display: inline-block;
  text-transform: uppercase;
  color: #ffffff;
  font-size: 14px;
  border: 1px solid #ffdb0a;
  padding: 13px 18px;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    color: #ffffff;
    border-color: #a2cd1d;
  }
}

.instruction {
  margin-top: 60px;
  display: flex;
  justify-content: space-between;

  &__item {
    width: 31%;

    &__link {
      display: flex;
      justify-content: space-between;
      margin-bottom: 7px;

      a {
        width: 48%;

        img {
          max-width: 140px;
        }
      }
    }
  }

  &__title {
    display: block;
    text-transform: uppercase;
    font-size: 14px;
    margin-bottom: 10px;

    &:hover {
      color: #000;
      text-decoration: none;
    } 
  }

  &__text {
    font-size: 14px;
    line-height: 1.2;
  }


  @include media-breakpoint-down(lg) {
    flex-direction: column;

    &__item {
      width: 100%;
      text-align: center;
      margin-bottom: 30px;
    

      &__link {
        justify-content: center;
        margin-bottom: 30px;
      
         a {
          width: auto;
          padding: 0 5px;
        }
      }
    }
  }



  @include media-breakpoint-down(sm) {
    &__item {
      &__link {
        flex-direction: column;
      
        a {
          padding: 7px 0;
        }
      }
    }
  }
}