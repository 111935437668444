.bg-modal {
  background-color: #D9D9D9;
}
.modal-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 20px;
  border: 1px solid var(--bs-secondary);
  background-color: var(--bs-secondary);
  color: #fff;
  font-size: 16px;
  stroke: #fff;
  stroke-width: 2px;

  &:hover {
    background-color: #149900;
  }
}

.modal-header {
  position: relative;
  justify-content: center;
  padding: 0 0 20px;
  border-bottom: none;
}
.modal-title {
  font-size: 16px;
  font-weight: 500;
  line-height: 18.81px;
  text-align: center;
  color: #020303;
}
.btn-close {
  position: absolute;
  top: -20px;
  right: -20px;
  z-index: 10;
}
.modal-body {
  padding: 0;
}
.modal-content {
  padding: 30px;
  border-radius: 0;
}
.modal-form-note {
  display: block;
  margin-top: 20px;
  text-align: center;
  color: #020303;
  opacity: 0.5;
}
.modal-md {
  @include media-breakpoint-down(md) {
    width: 300px;
    margin-left: auto;
    margin-right: auto;
  }
}