.hand-block {
  text-align: center;
  line-height: 0;
  position: relative;

  video {
    max-width: 100%;
  }

  &__repeat {
    position: absolute;
    bottom: 5px;
    left: 50%;
    display: flex;
    align-items: center;
    padding: 0;
    text-shadow: 0 0 5px #000;
    transform: translateX(-50%);
    font-size: 25px;
    color: #fff;
    border: none;
    background-color: transparent;
    transition: all .2s;

    &:hover {
      text-shadow: 0 0 12px #000;
    }
  }

  &__repeat-ico {
    display: inline-block;
    flex: none;
    margin-left: 10px;
    .ic {
      width: 21px;
      height: 24.5px;
      fill: #fff;
    }
  }


  @include media-breakpoint-down(md) {
    &__repeat {
      display: none;
    }
  }
}