.choose-model {
  &__list {
    &.d-flex {
      flex-wrap: wrap;
      width: 100%;
      margin: 0 -6px;
    }

    li {
      width: 20%;
      margin-bottom: 12px;
      padding: 0 6px;

      a {
        display: flex;
        width: 100%;
        height: 60px;
        padding: 5px;
        align-items: center;
        justify-content: center;
        background-color: #d7d7d7;
        font-size: 18px;
        color: #000;
        line-height: 1;
        text-align: center;
        text-transform: uppercase;
        text-decoration: none;

        &:hover,
        &.active {
          background-color: var(--bs-yellow);
        }
      }
    }
  }


  @include media-breakpoint-down(lg) {
    &__list li{
      width: 25%;
    }
  }

  @include media-breakpoint-down(md) {
    &__list.d-flex {
      margin: 0 -3px;
    }

    &__list li {
      width: 33.3333%;
      padding: 0 3px;
      margin-bottom: 6px;
    
      a {
        font-size: 14px;
        height: 45px;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    &__list li {
      width: 50%;
    }
  }
}



.choose-engine {
  &__list {
    li {
      text-align: center;
      margin-bottom: 6px;
       a {
        font-size: 28px;
        color: var(--bs-black);
        text-decoration: underline;
        &:hover{
          text-decoration: none;
          color: var(--bs-green);
        }
      }
    }
  }
}