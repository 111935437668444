.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 7px 0;
  z-index: 100;
  background-color: var(--bs-black);

  &__inner {
    position: relative;
  }

  &__mob-btn {
    display: none;
    width: 27px;
    padding: 0;
    border: none;
    background-color: transparent;

    span {
      display: block;
      height: 3px;
      margin-bottom: 4px;
      background-color: var(--bs-yellow);

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__logo {
    position: relative;
    top: -3px;

    img {
      width: 55px;
    }
  }

  &__close-menu {
    display: none;
    position: absolute;
    top: 15px;
    right: 20px;
    width: 23px;
    height: 21px;
    padding: 0;
    border: none;
    background-color: transparent;

    &:before, 
    &:after {
      content: '';
      position: absolute;
      top: 9px;
      left: 0;
      width: 25px;
      height: 3px;
      background-color: var(--bs-yellow);
      transform-origin: center;
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }

  &__top-menu {
    display: flex;
    align-items: center;
    margin: 0 12px;
    padding: 0;
    list-style-type: none;

    a {
      color: #fff;
      font-size: 11px;
      text-transform: uppercase;
      text-decoration: none;
      line-height: 1;
      white-space: nowrap;

      &:hover {
        color: var(--bs-yellow);
        text-decoration: none;
      }
    }

    li {
      margin: 0 12px;
    } 
  }

  &__top-btn-block {
    display: flex;
    padding-right: 10px;
  }

  &__top-btn {
    display: flex;
    align-items: center;
    justify-content: center; 
    margin-right: 9px;
    padding: 2px 10px;
    color: var(--bs-black);
    font-size: 12px;
    line-height: 1.2;
    text-transform: capitalize;
    text-align: center;
    background-color: var(--bs-yellow);
    text-decoration: none;

    &:hover {
      text-decoration: none;
      color: var(--bs-yellow);
      background-color: transparent;
    }

    &.header__top-btn__order {
      background: transparent;
      color: var(--bs-yellow);
      border: 1px solid var(--bs-yellow);

      &:hover {
        background: var(--bs-yellow);
        color: var(--bs-black);
      }
    }
  }

  &__top-status {
    display: none;

    &.active {
      display: block;
    }
  }


  &__top-basket {
    position: relative;
    cursor: pointer; 

    &__product__count {
      position: absolute;
      top: 50%;
      left: 60%;
      display: block;
      width: 13px;
      height: 13px;
      line-height: 13px;
      border-radius: 50%;
      background-color: var(--bs-yellow);
      color: var(--bs-black);
      font-size: 10px;
      text-align: center;
      transform: translate(-50%, -50%);
    }
  }


  &__top-car {
    position: relative;
    margin-right: 10px;

    &__model {
      position: absolute;
      top: calc(100% + 52px);
      width: 140px;
      padding: 15px 5px 10px;
      font-size: 11px;
      text-transform: uppercase;
      color: var(--bs-black);
      text-align: center;
      left: -10000px;
      opacity: 0;
      z-index: 10;
      background-color: #e1e1e1;
      transition: top .2s, opacity .2s;

      &:before {
        content: '';
        width: 0;
        height: 0;
        border: 8px solid transparent;
        border-bottom-color: #e1e1e1;
        position: absolute;
        top: -15px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 5;
      }
    }

    .check-car {
      position: absolute;
      right: 10px;
      top: 50%;
      display: none;
      width: 31px;
      height: 23px;
      background: url('../../assets/img/content/new/top-check.png') 0 0 no-repeat;
      background-size: contain;
      transform: translateY(-50%);
    }

    &:hover {
      .header__top-car__model {
        top: calc(100% + 14px);
        left: -19px;
        opacity: 1;
      }
    }

    &.active {
      .check-car {
        display: block;
      }

      .not-check-car {
        display: none;
      }
    }

    .not-check-car {
      position: absolute;
      right: 10px;
      top: 50%;
      width: 25px;
      height: 23px;
      background-size: contain;
      transform: translateY(-50%);

      &:before {
        content: '?';
        color: var(--bs-red);
        font-size: 25px;
      }
    }
  }

  &__mobile-phone {
    display: none;
  }

  &__icon-phone-link {
    position: relative;
    margin-right: 15px;
  }


  @include media-breakpoint-down(xl) {
    &__mobile-phone {
      display: block;
      width: 100%;
      margin-top: -10px;
      margin-bottom: 25px;

      a {
        text-decoration: none;
      }

      .icon-phone {
        margin-left: 9px;
        font-size: 16px;
        line-height: 15px;
        color: #FFFFFF;
      }
    }
    &__top-btn {
      max-width: 165px;
      margin: 0 0 10px;
      padding-top: 8px;
      padding-bottom: 5px;
      font-size: 12px;
      br {
        display: none;
      }
    }
    &__top-btn-block {
      flex-direction: column;
      width: 100%;
      margin: 0;
      padding: 0;
    }
    &__top-menu {
      flex-direction: column;
      align-items: flex-start;
      margin: 0 0 30px;
      li {
        margin: 0 0 15px;
      }
    }
    &__close-menu {
      display: block;
    }

    &__mob-btn {
      display: block;
    }
    &__nav {
      position: absolute;
      width: 260px;
      background-color: rgba(0, 0, 0, .8);
      top: 0;
      left: -1000px;
      padding: 65px 15px 40px;
      transition: left .3s;
      z-index: 20;

      &.active {
        left: 0;
      }
    }
  }

  @include media-breakpoint-only(xl) {
    &__inner {
      .container {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }
}
