.firmware-advantage {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 35px 25px;

  &__item {
    position: relative;
    width: 330px;
    height: 250px;
    padding: 23px 24px;
    background: #000;

    &:nth-child(1) {
      background: url('../../assets/img/content/adv-block/fir-tuning.jpg') no-repeat;
    }
    &:nth-child(2) {
      background: url('../../assets/img/content/adv-block/fir-engine.jpg') no-repeat;
    }
    &:nth-child(3) {
      background: url('../../assets/img/content/adv-block/fir-off.jpg') no-repeat;
    }
    &:nth-child(4) {
      background: url('../../assets/img/content/adv-block/fir-mode.jpg') no-repeat;
    }
    &:nth-child(5) {
      background: url('../../assets/img/content/adv-block/fir-program.jpg') no-repeat;
    }
    &:nth-child(6) {
      background: url('../../assets/img/content/adv-block/fir-sell.jpg') no-repeat;
    }

    p {
      position: absolute;
      bottom: 25px;
      left: 50%;
      transform: translateX(-50%);
      font-size: 14px;
      line-height: 18px;
      color: #ffffff;
      text-align: center;
      margin-bottom: 0;
      width: calc(100% - 48px);
    }
  }
}